// react-router-dom components
import { Link, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
//import api from 'assets/api';

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
///import DateRangePicker from '@mui/x-date-pickers-pro/DateRangePicker';

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Soft UI Dashboard PRO React Layout components.
import OfferTable from "layouts/offers/components/OfferTable";

function OfferList() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>        
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h3" fontWeight="medium">
                Offers
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                A lightweight, extendable, dependency-free javascript HTML table plugin.
              </SoftTypography>
            </SoftBox>
            <Stack spacing={1} direction="row">
              <Link to="/new-offers">
                <SoftButton variant="gradient" color="warning" size="large">
                  + new offers
                </SoftButton>
              </Link>
              {/*
                <SoftButton variant="gradient" color="warning" size="large" component={Link} to="/new-offers">
                  + new offers
                </SoftButton>
              */}                     
            </Stack>
          </SoftBox>
          <Card>
            <OfferTable />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default OfferList;
