// import hooks.
import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// EditStore page components
import StoreInfo from "layouts/edit-store/components/StoreInfo";
import SaveChanges from "layouts/edit-store/alerts/SaveChanges";
import ContinueSaveAlert from "layouts/edit-store/alerts/ContinueSaveAlert";

function EditStore() {
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  let storeId = params.get('storeId');
  //const currentDate = new Date().toLocaleDateString(); //just date.
  const currentDate = new Date().toLocaleString('en-GB', {
    hour12: false,
  }).replace(',','');
  const [postData, setPostData] = useState({
    active:true,
    address:"",
    createdDate:`${currentDate}`,
    storeId:`${storeId}`,
    phoneNo:"",
    description:"",
    title:"",
  });
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <SoftTypography variant="h2" fontWeight="regular">
                Make the changes below
              </SoftTypography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <ContinueSaveAlert />
                <SoftBox ml={1}>
                  <SaveChanges postData={postData} />
                </SoftBox>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
        <Grid container spacing={3} mt={4}>
          <Grid item xs={12}>
            <StoreInfo postData={postData} setPostData={setPostData} />
          </Grid>  
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditStore;
