// import hooks.
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
import $ from "jquery";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// sweetalert2 components
import Swal from "sweetalert2";

// Sweet Alerts page components
import Template from "layouts/authentication/reset-password/confirm-password/alerts/ConfirmPassword/Template";

function ConfirmPassword({ emailId, newPassword, postConfirmPwd, postOtp }) {
 const closeTab = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };

  const urlParams = new URLSearchParams(window.location.search);
  const test = urlParams.get('test')

  console.log(test)
    
  if (test=="test") {
    Swal.fire({
      title: "Success!",
      text:"Password reset successful! Please log in to the mobile application.",
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK'
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "/";
      }
    })
  }

  const showAlert = async(event) => {
    event.preventDefault();
    if(newPassword == postConfirmPwd){

      await axios.post(`${ApiRequests.adminChangePwdWithOtp}?email=${emailId}&newPassword=${newPassword}&OTP=${postOtp}`, {})
      .then((response) => {
        if(response?.status === 200){
          if (response.data.role=="admin") {
            Swal.fire({
              title: "Success!",
              text:"Password reset successful!",
              icon: "success",
            })
            setTimeout(function(){
              window.location.href = "/authentication/sign-in";
            },2000);
          } else {
            Swal.fire({
              title: "Success!",
              text:"Password reset successful! Please log in to the mobile application.",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/";
              }
            })
          }
        }
        else if(response?.status === 400){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: 'No user record found for the provided email!<br /><br /><strong>Please contact support.</strong>',
            footer: response.data,
            type:"error",
          })  
        }
        else if(response?.status === 404){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: 'Invalid User!<br /><br /><strong>Please contact support.</strong>',
            footer: response.data,
            type:"error",
          })
        }
      }).catch((err)=>{
        if(err.response){
          //Not in the 200 response range.
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: 'Something went wrong!<br /><br /><strong>Please contact support.</strong>',
            footer: err.response.data.message,
            type:"error",
          })
        }
        else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: 'Something went wrong!<br /><br /><strong>Please contact support.</strong>',
            footer: err.response.message,
            type:"error",
          })
        }
      });
    }
    else{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        html: 'Password you enter does not match!<br /><br />Please try again',
        type:"error",
      })
    }

/*
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });
    newSwal
      .fire({
        title: "Success!",
        text:"Your password has been changed.",
        icon: "success",
        confirmButtonText: "Continue to login",    
      })
      .then((result) => {
        if (result.isConfirmed) {
          ///axios function.
          axios.post(`${ApiRequests.adminResetPwd}`, {}, {params:{emailId, newPassword}})
          .then((response) => {
            console.log(response);
            window.location.href = "/authentication/sign-in";
          }).catch((err)=>{
              if(err.response){
                //Not in the 200 response range.
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
              }
              else{
                console.log(`Error: ${err.message}`);
              }
          });
        }
      });
*/
  };

  return (
    <Template
      action={showAlert}
    />
  );
}

ConfirmPassword.propTypes = {
  emailId: PropTypes.string,
  newPassword: PropTypes.string,
  postConfirmPwd: PropTypes.string,
  postOtp: PropTypes.string
};

export default ConfirmPassword;
