// import hooks.
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// sweetalert2 components
import Swal from "sweetalert2";

// Sweet Alerts page components
import Template from "layouts/offers/alerts/DeleteData/Template";

function DeleteData({ offerId }) {
  const navigate = useNavigate();
  const showAlert = (event) => {
    event.preventDefault();
/*    console.log(offerId);*/
    
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button btn-save button-success",
        cancelButton: "button btn-continue",
      },
      buttonsStyling: false,
    });
    newSwal
      .fire({
        title: "Delete offer?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        reverseButtons: true,
        cancelButtonText:"Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {
          //axios.post(`${ApiRequests.removeOffer}`, {}, {params:{offerId}})
          //axios.post(`${ApiRequests.removeOffer}`, offerId)
          axios({
            method: 'post',
            url:`${ApiRequests.removeOffer}`,
            headers: {
              'Content-Type': 'text/plain'
            },
            data:offerId,
          })
          .then((response) => {
            Swal.fire({title:"Deleted!", text:"Offer has been deleted.", type:"success", icon:"success"}).then(function(){
              //navigate("/offers");
              window.location.reload();
            });
          }).catch((err) => {
            Swal.fire({title:"Failed!", text:err.response.data.message, type:"error", icon:"error"})
              .then(function() {
              });
          });
        }
      });

  };

  return (
    <Template
      action={showAlert}
    />
  );
}

DeleteData.propTypes = {
  offerId: PropTypes.string,
};

export default DeleteData;
