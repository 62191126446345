// import hooks.
import { useEffect, useState } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";

// Soft UI Dashboard PRO React example components
import DashboardDoughnutChart from "examples/Charts/DoughnutCharts/DashboardDoughnutChart";
import PropTypes from "prop-types";

function DashboardDoughnutChartData({ data }){
    
    const values = [data?.female, data?.male, data?.others];
    let sum = 0;
    values.map(x => sum += x);

    //console.log(sum);
    const doughnutChartData = {
            labels: ["Female", "Male", "Others",],
            datasets: {
            label: "Users",
            backgroundColors: ["primary", "info", "secondary", "success", "warning"],
            data: values,
        },
    };

    return(
        <>
            <DashboardDoughnutChart
                title="Gender Demographic"
                count={{ number: `${sum}`, text: "Users" }}
                chart={doughnutChartData}
                tooltip="Gender Demographic in terms of users"
            />
        </>
    );
}

DashboardDoughnutChartData.propTypes = {
  //data: PropTypes.object,
  //data: PropTypes.array,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  //setData: PropTypes.func,
};

export default DashboardDoughnutChartData;