// sweetalert2 components
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
import $ from "jquery";

// react-router-dom components
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// sweetalert2 components
import Swal from "sweetalert2";

// Sweet Alerts page components
import Template from "layouts/edit-store/alerts/SaveChanges/Template";

let minAddress = 4;

function isNumeric(str) {
  if (typeof str != "string") return false
  return !isNaN(str) &&
         !isNaN(parseFloat(str))
}

function SaveChanges({ postData }) {
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  let storeId = params.get('storeId');
  
  const navigate = useNavigate();

  const showAlert = (event) => {
    event.preventDefault();
/*    console.log(postData);*/
 
   const newSwal = Swal.mixin({
     customClass: {
       confirmButton: "button btn-save button-success",
       cancelButton: "button btn-continue",
     },
     buttonsStyling: false,
   });

   let notValid = 0;
   let optionalField = [];
   $(".errCs").remove();
   $("input").each(function(i,obj){
     let attrid = $(obj).attr("id");
     let selection = false;  

     if (typeof attrid!=="undefined") {
       if (attrid.includes("react-select")) {
         selection = true;
       }
     }

     if (!selection) {
       if ($(obj).val()==""){
         if (!optionalField.includes($(obj).attr("name"))){
           if ($(obj).attr("placeholder")=="Select a date") {
             $(obj).parent().parent().parent().append("<span class='errCs'>Required!</span>");
           } else {
             $(obj).parent().parent().append("<span class='errCs'>Required!</span>");
           }
           notValid+=1;
         }

          if ($(obj).attr("id")=="phone_no") {
            if (!isNumeric($(obj).val())){
              $(obj).parent().parent().append("<span class='errCs'> Please input numbers only!</span>");
              notValid+=1;
            }
          }
       }
     }
   })

    if ($("#address").val().length<minAddress){
      $($("#address")).parent().parent().append("<span class='errCs'> Address minimal 4 digit!</span>");
      notValid+=1;
    }

   $("textarea").each(function(i,obj){
     if ($(obj).val()==""){
       if (!optionalField.includes($(obj).attr("name"))) {
         $(obj).parent().parent().append("<span class='errCs'>Required!</span>");
         notValid+=1;
       }
     }
   })

   if (!$("#latlong").val().includes(",")){
      $($("#latlong")).parent().parent().append("<span class='errCs'> Please separate with comma for lattitude and longitude!</span>");
      notValid+=1;
   }

   if (notValid==0) {
   newSwal
     .fire({
       title: "Save Changes?",
       icon: "question",
       showCancelButton: true,
       confirmButtonText: "Save",
       reverseButtons: true,
       cancelButtonText:"Cancel",
     })
     .then((result) => {
       if (result.isConfirmed) {

         var timestamp = new Date();

          let d = timestamp.getDate()
          let m = timestamp.getMonth() + 1;
          let y = timestamp.getFullYear();

          var seconds = timestamp.getSeconds();
          var minutes = timestamp.getMinutes();
          var hour = timestamp.getHours();

          if (d>9) {
            d = d;
          } else {
            d = "0" + d;
          }

          if (m>9) {
            m = m;
          } else {
            m = "0" + m;
          }

          let createdDate = d + "/" + m + "/" + y + " " + hour + ":" + minutes + ":" + seconds;

          let bodyFormData = JSON.parse(localStorage.getItem("detailStore"+storeId));

          let reqlat = $("#latlong").val().split(",");

          bodyFormData["address"]=$("#address").val();
          bodyFormData["description"]=$("#description").val();
          bodyFormData["phoneNo"]=$("#phone_number").val();
          bodyFormData["title"]=$("#title").val();
          bodyFormData["latlong"]= {
            latitude: reqlat[0].replace(/ /g,""),
            longitude: reqlat[1].replace(/ /g,"")
          };

         axios({
           method:"post",
           url:`${ApiRequests.updateStore}`,
           data:bodyFormData,            
         }).then((response) => {
           Swal.fire({title:"Saved!", text:"Your changes have been saved.", type:"success", icon:"success"}).then(function(){
              navigate("/our-stores");
              //window.location.href = "/our-stores";
           });
         }).catch((err) => {
           Swal.fire({title:"Failed!", text:err.response.data.message, type:"error", icon:"error"})
             .then(function() {
             });
         });
       }
     });
    }
};

  return (
    <Template
      action={showAlert}
    />
  );
}

SaveChanges.propTypes = {
  postData: PropTypes.object,
};

export default SaveChanges;
