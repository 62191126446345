// import hooks
import { useState } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";

// react-router-dom components
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// sweetalert2 components
import Swal from "sweetalert2";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewOffer page components
import ContinueSaveAlert from "layouts/new-offers/alerts/ContinueSaveAlert";
import OfferInfo from "layouts/new-offers/components/OfferInfo";
import Media from "layouts/new-offers/components/Media";
import AdditionalInfo from "layouts/new-offers/components/Additional";
import Notification from "layouts/new-offers/components/Notification";
import $ from "jquery";

function NewOffer() {
  const navigate = useNavigate();
  //const currentDate = new Date().toLocaleDateString(); //just date.
  const currentDate = new Date().toLocaleString('en-GB', {
    hour12: false,
  }).replace(',','');
  
  const randId = btoa(Math.random()).slice(0, 13);
  const [postData, setPostData] = useState({
    active:true,
    notificationId:"",
    ageRange:"",
    brand:"",
    createdTime:`${currentDate}`,
    description:"",
    endDate:"",
    featureOffer:true,
    featuredImageURL:"",
    gender:"",
    notificationCategory:"",
    notificationText:"",
    //offer:"",
    offerScope:"",
    //offerId:`${randId}`,
    offerId:"",
    offerImageURLs:"",
    prevPrice:"",
    startDate:"",
    title:"",
  });

  const getSteps = () => {
    return ["1. Offer Info", "2. Media", "3. Additional", "4. Notification"];
  }

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <OfferInfo postData={postData} setPostData={setPostData} />;
      case 1:
        return <Media postData={postData} setPostData={setPostData} />;
      case 2:
        return <AdditionalInfo postData={postData} setPostData={setPostData} />;
      case 3:
        return <Notification postData={postData} setPostData={setPostData} />;
      default:
        return null;
    }
  }

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const handleNext = function(){
    let notValid = 0;

    let optionalField = ["prevPrice","offerImageURLs","featuredImageURL","ageRange","brand","notificationText"];

    $(".errCs").remove();
    $("input").each(function(i,obj){
      let attrid = $(obj).attr("id");
      let selection = false;  

      if (typeof attrid!=="undefined") {
        if (attrid.includes("react-select")) {
          selection = true;
        }
      }

      if (!selection) {
        if ($(obj).val()==""){
          if (!optionalField.includes($(obj).attr("name"))){
            if ($(obj).attr("placeholder")=="Select a date") {
              $(obj).parent().parent().parent().append("<span class='errCs'>Required!</span>");
            } else {
              $(obj).parent().parent().append("<span class='errCs'>Required!</span>");
            }
            notValid+=1;
          }
        }
      }
    })
    $("textarea").each(function(i,obj){
      if ($(obj).val()==""){
        if (!optionalField.includes($(obj).attr("name"))) {
          $(obj).parent().parent().append("<span class='errCs'>Required!</span>");
          notValid+=1;
        }
      }
    })
    if (notValid==0) {
      setActiveStep(activeStep + 1);
    }
  }
  const handleBack = () => setActiveStep(activeStep - 1);
  const handleCreate = (event) => {
    event.preventDefault();
    //console.log(postData);
/* */   
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button btn-save button-success",
        cancelButton: "buttton btn-quit",
        //denyButton: "button btn-continue",
      },
      buttonsStyling: false,
    });
    newSwal
      .fire({
        title: "Create or Quit",
        text: "Would you like to create this offer or quit?",
        icon: "question",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Create",
        //confirmButtonText: "Save",
        //denyButtonText: "Create",
        reverseButtons: true,
        cancelButtonText:"Quit",   
      })
      .then((result) => {
/*
        if (result.isConfirmed) {
          Swal.fire({title:"Saved!", text:"Your progress has been saved.", type:"success", icon:"success"}).then(function(){
            window.location.href = "/offers";
          });
        }
        else if (result.isDenied) {
*/
        if (result.isConfirmed) {
          //axios function.

          if (postData.gender=="") {
            postData.gender = null;
          }

          if (postData.ageRange=="") {
            postData.ageRange = null;
          }

          if (postData.offerImageURLs=="") {
            postData.offerImageURLs = [];
          }

          if (postData.notificationCategory=="") {
            postData.notificationCategory = [];
          }

          axios({
            method:"post",
            url:`${ApiRequests.createOffer}`,
            data:postData,
          }).then((response) => {
            Swal.fire({title:"Created!", text:"Offer has been created.", type:"success", icon:"success"}).then(function(){
                navigate("/offers");
                //window.location.href = "/offers";
              });
          }).catch((err) => {
            Swal.fire({title:"Failed!", text:err.response.data.message, type:"error", icon:"error"})
              .then(function() {
              });
          });    
        }
        else if (result.dismiss === Swal.DismissReason.cancel) {
          navigate("/offers");
          //window.location.href = "/offers";
        }
      });

  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox pt={6} pb={8}>
      <SoftBox display="flex" justifyContent="flex-end" mr={12}>
          <ContinueSaveAlert />
        </SoftBox>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <SoftBox mb={1} textAlign="center">
              <SoftBox mb={1}>
                <SoftTypography variant="h1" fontWeight="regular">
                  Create New Offer
                </SoftTypography>
              </SoftBox>
              <SoftTypography variant="h6" fontWeight="regular" color="secondary">
                This information will let us know what the new offer is.
              </SoftTypography>
            </SoftBox>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Card sx={{ overflow: "visible" }}>
              <SoftBox p={2}>
                <SoftBox>
                  {getStepContent(activeStep)}
                  <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    {activeStep === 0 ? (
                      <SoftBox />
                    ) : (
                      <SoftButton variant="gradient" color="secondary" onClick={handleBack}>
                        back
                      </SoftButton>
                    )}
                    <SoftButton
                      variant="gradient"
                      color="warning"
                      onClick={!isLastStep ? handleNext : handleCreate}
                    >
                      {isLastStep ? "create" : "next"}
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}
export default NewOffer;
