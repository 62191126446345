/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Rating from '@mui/material/Rating';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useEffect, useState } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";

function FeaturedOffer({obj, value}) {
  const offerId = obj.offerId;
  let activeVal = obj.featureOffer;
  const [statusChecked, setStatus] = useState(value);

  const switchHandler = (event)=>{
    event.preventDefault();
    let csname = event.target.labels[0].firstElementChild.className;

    let paramreq

    if (statusChecked==1) {
    	setStatus(0);
    	paramreq = false;
	} else {
		setStatus(1);
		paramreq = true;
	}

    axios.post(`${ApiRequests.toggleFeatured}`, {}, {params:{featureOffer:paramreq, offerId:offerId}})
    .then((response) => {
      //console.log(response);
      console.log(response.status);
      console.log(response.request.responseURL);

    }).catch((err) => {
      if(err.response){
        //Not in the 200 response range.
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      }
      else{
        console.log(`Error: ${err.message}`);
      }      
    });
    
  };
  return (
    <SoftBox>
      <Rating name="featured_offer" max={1} value={statusChecked} onChange={switchHandler}/>
    </SoftBox>
  );
}

// Setting default value for the props of FeaturedOffer
FeaturedOffer.defaultProps = {
  value: 0,
};

// Typechecking props for the FeaturedOffer
FeaturedOffer.propTypes = {
  //id: PropTypes.string.isRequired,
  obj: PropTypes.object,
  value: PropTypes.number,
};

export default FeaturedOffer;
