// import hooks.
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// sweetalert2 components
import Swal from "sweetalert2";

// Sweet Alerts page components
import Template from "layouts/authentication/reset-password/illustration/alerts/SendVerification/Template";

function SendVerification({ emailID }) {
  const resetPwd = async(event) => {
    event.preventDefault();
    //console.log(emailID);
    await axios.post(`${ApiRequests.adminResetPwd}?email=${emailID}`, {})
    .then((response) => {
    /// successful send email successful
      if(response?.status === 200){
          Swal.fire({
            title: "The password reset link has been sent.",
            text:"Please check your email.",
            icon: "success",
            confirmButtonText: "ok",
            footer:response.data,
            type:"success",
          })
        }
      else if(response?.status === 400 || response.data.message === "No admin record found"){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: 'Invalid User!<br /><br /><strong>Please contact support.</strong>',
          footer: response.data,
          type:"error",
        })
      }      
    }).catch((err)=>{
      if(err.response){
        //Not in the 200 response range.
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: 'Something went wrong!<br /><br /><strong>Please contact support.</strong>',
          footer: err.response.data.message,
          type:"error",
        })
      }
      else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: 'Something went wrong!<br /><br /><strong>Please contact support.</strong>',
          footer: err.message,
          type:"error",
        })
      }
    });
  };

  return (
    <Template
      action={resetPwd}
    />
  );
}

SendVerification.propTypes = {
  emailID: PropTypes.string,
};

export default SendVerification;
