// react-router-dom components
import { Link } from "react-router-dom";
import axios from "AxiosModule";
// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
///import DateRangePicker from '@mui/x-date-pickers-pro/DateRangePicker';

// Soft UI Dashboard PRO React example components
import ExistLoyaltyCard from "layouts/users/alerts/ExistLoyaltyCard"
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ApiRequests from "ApiRequests";

// Soft UI Dashboard PRO React Layout components.
import UserTable from "layouts/users/components/UserTable";

function replaceNullWithEmptyString(value) {
  if (value == null) {
    return '-';
  } else {
    if (value.length>0) {
      return value.replaceAll(",","");
    } else {
      return value;
    }
  }
}

function downloadCSVFile(csv_data) {
    let CSVFile = new Blob([csv_data], { type: "text/csv" });
    var temp_link = document.createElement('a');
    let timestamp = new Date();
    temp_link.download = timestamp.toISOString() + ".csv";
    var url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);
    temp_link.click();
    document.body.removeChild(temp_link);
    document.body.style.filter = 'none';
}

function exportAll(){
  document.body.style.filter = 'blur(3px)';
  let urlTarget = `${ApiRequests.exportAllUser}`;
  axios.get(urlTarget).then(res => {
    let datas = res.data;

    var csv_data = [];

    var rows = datas[0];
    var csvrow = [];
    for (let key in rows) {
      csvrow.push(key.replaceAll("_"," "));
    }

    csv_data.push(csvrow.join(","));

    var rows = datas;
    for (var i = 0; i < rows.length; i++) {
      var cols = rows[i];
      var csvrow = [];
      for (let key in cols) {
        let valCs = replaceNullWithEmptyString(cols[key]);
        csvrow.push(valCs);
      }
      csv_data.push(csvrow.join(","));  
    }

    csv_data = csv_data.join('\n');
    downloadCSVFile(csv_data);
  })
}

function UserList() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
          <SoftBox lineHeight={1}>
            <SoftTypography variant="h3" fontWeight="medium">
              Users
            </SoftTypography>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              A lightweight, extendable, dependency-free javascript HTML table plugin.
            </SoftTypography>
          </SoftBox>
          <Stack spacing={1} direction="row">
            <button className="btn btn-success" onClick={exportAll}>Export All</button>
            <ExistLoyaltyCard />
          </Stack>
        </SoftBox>
        <Card>
          <UserTable />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UserList;
