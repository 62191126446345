// import hooks.
import { useEffect, useState, useRef } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// EditOffer page components
import FormField from "layouts/edit-offer/components/FormField";

function Notification({ postData, setPostData }) {
  const notificationCategoryOption = [
    { value: "OFFERS", label: "OFFERS" },
    { value: "MOBILE", label: "MOBILE"},
    { value: "GENERAL", label: "GENERAL" },
    { value: "SALE", label: "SALE" },
    { value: "EXPIRED", label: "EXPIRED" },
  ];
/**
 *  read API function 
 */  
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  let offerId = params.get('offerId');

  const [notifs, setNotifs] = useState([]);

  const handleInput = (event) => {
    setPostData({...postData, [event.target.name]: event.target.value});
  };

  useEffect(() => {  
    let selectedOption = postData.notificationCategory;
    let mapped = [];

    for (var i=0;i<selectedOption.length;i++) {
      mapped.push({value:selectedOption[i],label:selectedOption[i]});
    }

    setNotifs(mapped);
  }, [postData]);

  const handleSelect = (option) => {
    setNotifs((notifs)=>option);
    let options = [];
      option.map(val =>
      options.push(val.value)
    );
    setPostData({...postData, "notificationCategory":options})
  };

  return (
    <Card sx={{ overflow: "visible" }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5" fontWeight="regular">
          Notification
        </SoftTypography>
        <SoftBox mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormField type="text" label="text for notification" value={postData?.notificationText} onChange={handleInput} name="notificationText" />
            </Grid>
            <Grid item xs={12}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography component="label" variant="button" fontWeight="regular">
                  Notification category
                </SoftTypography>
              </SoftBox>
              <SoftSelect
                value={notifs}
                options={notificationCategoryOption}
                onChange={handleSelect}
                size="large"
                isMulti
              />
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

Notification.propTypes = {
  postData: PropTypes.object,
  setPostData: PropTypes.func,
};

export default Notification;
