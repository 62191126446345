// import hooks.
import { useEffect, useState } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

// EditBrand page components
import FormField from "layouts/edit-branch/components/FormField";

function BranchInfo({ postData, setPostData }) {
/**
 *  read API function 
 */
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  let branchId = params.get('branchId');
/// initialize state using useState
  const [data, setData] = useState([]);
/// axios req & res
  const readBranch = async () => {
    try{
        const response = await axios
        .get(`${ApiRequests.readBranch+branchId}`)
        .then((response) => {
        setData(response.data);
        //console.log(response)
      });
    }catch(err){
      if(err.response){
        //Not in the 200 response range.
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      }
      else{
        console.log(`Error: ${err.message}`);
      }
    }
  };
/// tell React that your component needs to do something after render.
  useEffect(() => {  
    readBranch();
  }, []);
  //console.log(data.description);

/**
 * update API function.
 */
  const handleInput = (event) => {
    setData({...data, [event.target.name]: event.target.value});
    setPostData({...postData, [event.target.name]: event.target.value});
  };

  return (
    <Card sx={{ overflow: "visible" }} mt={4} mb={4}>
      <SoftBox p={3}>
        <SoftTypography variant="h5" fontWeight="regular">Branch Information</SoftTypography>
        <SoftBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField type="text" label="Branch Name*" value={data?.branchName} onChange={handleInput} name="branchName" required />
            </Grid>
            <Grid item xs={12}>
              <FormField type="text" label="Branch Code*" value={data?.branchCode} onChange={handleInput} name="branchCode" required />
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

BranchInfo.propTypes = {
  postData: PropTypes.object,
  setPostData: PropTypes.func,
};

export default BranchInfo;
