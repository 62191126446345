// import hooks.
import { useEffect, useState } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
import { useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftEditor from "components/SoftEditor";
import SoftSelect from "components/SoftSelect";
import SoftDatePicker from "components/SoftDatePicker";
import PropTypes from "prop-types";

// EditUser page components
import FormField from "layouts/edit-user/components/FormField";

function BasicInfo({data, setData}) {

  const handleInput = (event) => {
    setData({...data, [event.target.name]: event.target.value});
  };

  const handleInputDob = (selectedDates, dateStr, instance) => {
    setData({...data, "dob": dateStr});
  };

  const handleSelectTitle = (option) => {
    setData({...data, "title": option.value});
  };

  const handleSelectGender = (option) => {
    setData({...data, "gender": option.value});
  };

  const titleOption = [
    { value: "Sir", label: "Sir" },
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Miss", label: "Miss" },
    { value: "Ms", label: "Ms" },
    { value: "Dr", label: "Dr" },
  ];

  const genderOption = 
  [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "others", label: "Others" },
  ];

  return (
    <Card sx={{ overflow: "visible" }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5" fontWeight="regular">Basic Information</SoftTypography>
        <SoftBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField name="email" type="email" onChange={handleInput} label="email*" value={data?.email} required/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <SoftBox mb={2}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    textTransform="capitalize"
                  >
                    Title
                  </SoftTypography>
                </SoftBox>
                <SoftSelect onChange={handleSelectTitle}
                  value={titleOption.find(({value}) => value === data?.title)}
                  options={titleOption}
                />
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField name="firstName" type="text" label="first name*" onChange={handleInput} value={data?.firstName} required/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField name="lastName" type="text" label="last name*" onChange={handleInput} value={data?.lastName} required/>
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <SoftBox mb={2}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    textTransform="capitalize"
                  >
                    Gender
                  </SoftTypography>
                </SoftBox>
                <SoftSelect onChange={handleSelectGender}
                  options={genderOption}
                  value={genderOption.find(({value}) => value === data?.gender)}
                />
              </SoftBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <SoftBox mb={2}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="button" fontWeight="regular">
                    DOB*
                  </SoftTypography>
                </SoftBox>
                {/*<SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">*/}
                  <SoftDatePicker onChange={handleInputDob} options={{dateFormat:"d/m/Y H:i:S"}} value={data?.dob} input={{ placeholder: 'Select DOB' }} />  
                {/*</SoftBox>*/}
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

BasicInfo.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
};

export default BasicInfo;
