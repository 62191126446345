import { useState } from "react";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewBrand page components
import ContinueSaveAlert from "layouts/new-brand/alerts/ContinueSaveAlert";
import BrandInfo from "layouts/new-brand/components/BrandInfo";

function NewBrand() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox pt={6} pb={8}>
      <SoftBox display="flex" justifyContent="flex-end" mr={12}>
          <ContinueSaveAlert />
        </SoftBox>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <SoftBox mb={4} textAlign="center">
              <SoftBox mb={1}>
                <SoftTypography variant="h1" fontWeight="regular">
                  Create New Brand
                </SoftTypography>
              </SoftBox>
              <SoftTypography variant="h6" fontWeight="regular" color="secondary">
                More information about new brand.
              </SoftTypography>
            </SoftBox>
            <Card sx={{ overflow: "visible" }}>
              <SoftBox p={2} lineHeight={1}>
                <BrandInfo />
              </SoftBox>
            </Card>            
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewBrand;
