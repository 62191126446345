// import hooks.
import { useEffect, useState } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftEditor from "components/SoftEditor";
import SoftSelect from "components/SoftSelect";
import SoftDatePicker from "components/SoftDatePicker";

// EditOffer page components
import FormField from "layouts/edit-offer/components/FormField";

function OfferInfo({ postData, setPostData }) {

  const handleInput = (event) => {
    setPostData({...postData, [event.target.name]: event.target.value});
  };
  const handleInputStartDate = (selectedDates, dateStr, instance) => {
    setPostData({...postData, "startDate": dateStr});
  };
  const handleInputEndDate = (selectedDates, dateStr, instance) => {
    setPostData({...postData, "endDate": dateStr});
  };
  return (
    <Card sx={{ overflow: "visible" }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5" fontWeight="regular">Offer Information</SoftTypography>
        <SoftBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField type="text" label="title*" value={postData?.title} onChange={handleInput} name="title" required />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField type="text" label="offer* (max. 25 characters)" value={postData?.offerScope} onChange={handleInput} name="offerScope" required />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography component="label" variant="button" fontWeight="regular">
                  Description*&nbsp;&nbsp;
                </SoftTypography>
              </SoftBox>
              <SoftInput value={postData?.description} onChange={handleInput} name="description" multiline rows={5} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SoftBox mb={3}>
                <FormField type="text" label="Previous price (only active if used)" value={postData?.prevPrice} onChange={handleInput} name="prevPrice" required />
              </SoftBox>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="block">
                    <SoftTypography component="label" variant="button" fontWeight="regular">
                      Start Date*&nbsp;&nbsp;
                    </SoftTypography>
                    {/*<SoftDatePicker onChange={handleInputStartDate} options={{dateFormat:"d/m/Y"}} input={{ placeholder: `${postData?.startDate}` }} />*/}
                    <SoftDatePicker onChange={handleInputStartDate} options={{dateFormat:"d/m/Y H:i:S"}} input={{ value: `${postData?.startDate}` }} />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="block">
                    <SoftTypography component="label" variant="button" fontWeight="regular">
                      End Date*&nbsp;&nbsp;
                    </SoftTypography>
                    {/*<SoftDatePicker onChange={handleInputEndDate} options={{dateFormat:"d/m/Y"}} input={{ value: `${postData?.endDate}` }} />*/}
                    <SoftDatePicker onChange={handleInputEndDate} options={{dateFormat:"d/m/Y H:i:S"}} input={{ value: `${postData?.endDate}` }} />
                  </SoftBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

OfferInfo.propTypes = {
  postData: PropTypes.object,
  setPostData: PropTypes.func,
};

export default OfferInfo;
