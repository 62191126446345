// import hooks.
import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import ConfirmPassword from "layouts/authentication/reset-password/confirm-password/alerts/ConfirmPassword";

// Image
import lock from "assets/images/illustrations/lock.png";

function Illustration() {
  const [postEmail, setPostEmail] = useState("");
  const [postPwd, setPostPwd] = useState("");
  const [postConfirmPwd, setPostConfirmPwd] = useState("");
  const [postOtp, setPostOtp] = useState("");

  return (
    <IllustrationLayout
      title="Reset Password"
      description="Please create a new password."
      /*
      description="You will receive an e-mail in maximum 60 seconds"      
      illustration={{
        image: lock,
      }}
      */
    >
      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          <SoftInput onChange={(event) => {setPostEmail(event.target.value)}} type="email" placeholder="Email" size="large" icon={{component: "email", direction: "left", }}/>
        </SoftBox>
        <SoftBox mb={2}>
          <SoftInput onChange={(event) => {setPostPwd(event.target.value)}} type="password" placeholder="Password*" size="large" icon={{component: "lock", direction: "left", }}/>
        </SoftBox>
        <SoftBox mb={2}>
          <SoftInput onChange={(event) => {setPostConfirmPwd(event.target.value)}}type="password" placeholder="Confirm Password*" size="large" icon={{component: "lock", direction: "left", }}/>
        </SoftBox>
        <SoftBox mb={2}>
          <SoftInput onChange={(event) => {setPostOtp(event.target.value)}}type="number" placeholder="OTP*" size="large" icon={{component: "lock", direction: "left", }}/>
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <ConfirmPassword emailId={postEmail} newPassword={postPwd} postConfirmPwd={postConfirmPwd} postOtp={postOtp}/>
        </SoftBox>
      </SoftBox>
    </IllustrationLayout>
  );
}

export default Illustration;
