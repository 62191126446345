import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
import { useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// EditUser page components
import BasicInfo from "layouts/edit-user/components/BasicInfo";
import CardInfo from "layouts/edit-user/components/CardInfo";
import ContactInfo  from "layouts/edit-user/components/ContactInfo";
import SaveChanges from "layouts/edit-user/alerts/SaveChanges";
import ContinueSaveAlert from "layouts/edit-user/alerts/ContinueSaveAlert";
  
function EditUser() {
  
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  let userid = params.get('uid');
  const readUser = async () => {
    try{
        const response = await axios
        .get(`${ApiRequests.readUser+userid}`)
        .then((response) => {
        setData(response.data)
        //console.log(response)
      });
    }catch(err){
      if(err.response){
        //Not in the 200 response range.
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      }
      else{
        console.log(`Error: ${err.message}`);
      }
    }
  };

  const [data, setData] = useState({
    accountKey:"",
    active:false,
    address:"",
    branchCode:"",
    branchName:"",
    cardNo:"",
    cardPin:"",
    dob:"",
    email:"",
    firstName:"",
    gender:"",
    hasLoyaltyCard:"",
    joinedDate:"",
    lastName:"",
    localStore:"",
    notificationsEnabled:"",
    phoneNo:"",
    pointsAccumulated:"",
    promotionsEnabled:"",
    title:"",
    uid:""
  });

  useEffect(() => {  
    readUser();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <SoftTypography variant="h2" fontWeight="regular">
                Make the changes below
              </SoftTypography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <ContinueSaveAlert data={data} setData={setData}/>
                <SoftBox ml={1}>
                  <SaveChanges data={data} setData={setData}/>
                </SoftBox>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <BasicInfo data={data} setData={setData}/>
              </Grid>
              <Grid item xs={12}>
                <ContactInfo data={data} setData={setData}/>
              </Grid>
            </Grid>     
          </Grid>
          <Grid item xs={12} lg={4}>
            <CardInfo data={data} setData={setData} />
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditUser;
