/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// LoyaltyCardDetails page components
import ContinueSaveAlert from "layouts/loyalty-card/alerts/ContinueSaveAlert";
import LoyaltyCardInfo from "layouts/loyalty-card/components/LoyaltyCardInfo";

function LoyaltyCardDetails() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox pt={6} pb={8}>        
        <SoftBox sx={{display:"grid", gridTemplateColumns: "1fr repeat(2, auto)", justifyItem:"center"}}>
          <SoftBox sx={{ gridColumnStart:"1" }}> 
            <Grid container justifyContent="center">
              <Grid item xs={12} lg={8}>
                <SoftBox mb={4} textAlign="center">
                  <SoftBox mb={1}>
                    <SoftTypography variant="h1" fontWeight="regular">
                      Create New User
                    </SoftTypography>
                  </SoftBox>
                  <SoftTypography variant="h6" fontWeight="regular" color="secondary">
                    Input card details below.
                  </SoftTypography>
                </SoftBox>
                <Card sx={{ overflow: "visible" }}>
                  <SoftBox p={2} lineHeight={1}>
                    <LoyaltyCardInfo />
                  </SoftBox>
                </Card>            
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox sx={{ml:""}} pr={12}>
            <ContinueSaveAlert />
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default LoyaltyCardDetails;
