/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Web app by: https://www.speire.ie
* Copyright 2023

 =========================================================
*
*/

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// IMPORT CUSTOM CSS STYLESHEET
import 'assets/css/style.css';
import 'assets/css/bootstrap.css';

// Soft UI Context Provider
import { SoftUIControllerProvider } from "context";

const root = ReactDOM.createRoot(document.getElementById("root"));

const user_login = JSON.parse(localStorage.getItem("user_login"));
const path = window.location.pathname;
const publicPath = ["/authentication/sign-in","/privacy-policy","/about-us","/","/terms-and-conditions","/authentication/reset-password/confirm-password","/authentication/reset-password/illustration"];

console.log(path)
if (!user_login) {
	if (!publicPath.includes(path)) {
		window.location.href = "/authentication/sign-in";
	}
} else {
	// do user login
}

root.render(
  <BrowserRouter>
    <SoftUIControllerProvider>
      <App />
    </SoftUIControllerProvider>
  </BrowserRouter>
);