// import hooks.
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// sweetalert2 components
import Swal from "sweetalert2";

// Sweet Alerts page components
import Template from "layouts/stores/alerts/DeleteData/Template";

function DeleteData({ storeId }) {
  const navigate = useNavigate();
  const showAlert = (event) => {
    event.preventDefault();
    //console.log(storeId);
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button btn-save button-success",
        cancelButton: "button btn-continue",
      },
      buttonsStyling: false,
    });
    newSwal
      .fire({
        title: "Delete store?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        reverseButtons: true,
        cancelButtonText:"Cancel",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await axios.post(`${ApiRequests.removeStore}`, {}, {params:{storeId}})
          .then((response) => {
            Swal.fire({title:"Deleted!", text:"Store has been deleted.", type:"success", icon:"success"}).then(function(){
              //navigate("/our-stores");
              window.location.reload();
            });
          }).catch((err) => {
            Swal.fire({title:"Failed!", text:err.response.data.message, type:"error", icon:"error"})
              .then(function() {
              });
          });
        }
      });
  };

  return (
    <Template
      action={showAlert}
    />
  );
}

DeleteData.propTypes = {
  storeId: PropTypes.string,
};

export default DeleteData;
