// react-router-dom components
import { useNavigate } from "react-router-dom";

// sweetalert2 components
import Swal from "sweetalert2";

// Sweet Alerts page components
import Template from "layouts/new-store/alerts/CancelFormAlert/Template";

function CancelFormAlert() {
  const navigate = useNavigate();
  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button btn-save button-success",
        cancelButton: "buttton btn-quit",
      },
      buttonsStyling: false,
    });
    newSwal
      .fire({
        title: "Continue or Quit",
        text: "Would you like to continue creating this store or quit?",
        icon: "warning",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Continue",
        reverseButtons: true,
        cancelButtonText:"Quit",
      })
      .then((result) => {
/*
        if (result.isConfirmed){
          Swal.fire({title:"Saved!", text:"Your progress has been saved.", type:"success", icon:"success"}).then(function(){
            navigate("/our-stores");
            //window.location.href = "/our-stores";
          });
        }else 
*/
        if (result.dismiss === Swal.DismissReason.cancel){
          navigate("/our-stores");
          //window.location = "/our-stores";
        }
      });
  };

  return (
    <Template
      action={showAlert}
    />
  );
}

export default CancelFormAlert;
