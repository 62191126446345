/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Web app by: https://www.speire.ie
* Copyright 2023

 =========================================================
*
*/

/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard PRO React layouts
import LockIllustration from "layouts/authentication/lock/illustration";

//react layouts custom pages.
import Dashboard from "layouts/dashboard";
import Offers from "layouts/offers";
import Users from "layouts/users";
import Analytics from "layouts/analytics";
import Stores from "layouts/stores";
import Brands from "layouts/brands";
import Branch from "layouts/branch";
import Notification from "layouts/notification";
import Settings from "layouts/settings";
import NewOffers from "layouts/new-offers";
import NewUsers from "layouts/new-users";
import LoyaltyCardDetails from "layouts/loyalty-card";
import NewStore from "layouts/new-store";
import NewBrand from "layouts/new-brand";
import NewBranch from "layouts/new-branch";
import NewNotification from "layouts/new-notification";
import EditOffer from "layouts/edit-offer";
import EditUser from "layouts/edit-user";
import EditStore from "layouts/edit-store";
import EditBrand from "layouts/edit-brand";
import EditBranch from "layouts/edit-branch";
import EditNotification from "layouts/edit-notification";
import PrivacyCookiePolicy from "layouts/privacy-cookie-policy";
import PrivacyPolicy from "layouts/privacy-policy";
import TermAndConditions from "layouts/term-and-conditions";
import AboutUs from "layouts/about-us";
import AboutShuzgroup from "layouts/about-shuzgroup";

import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import ResetIllustration from "layouts/authentication/reset-password/illustration";
import ConfirmPassword from "layouts/authentication/reset-password/confirm-password";
import VerificationIllustration from "layouts/authentication/2-step-verification/illustration";
import Error404 from "layouts/authentication/error/404";
import Error500 from "layouts/authentication/error/500";

// Soft UI Dashboard PRO React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
///import SettingsIcon from "examples/Icons/Settings";
import Basket from "examples/Icons/Basket";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";

//custom icons
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import GroupsIcon from '@mui/icons-material/Groups';
import SignalCellularAltRoundedIcon from '@mui/icons-material/SignalCellularAltRounded';
import DiscountIcon from '@mui/icons-material/Discount';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';

const routes = [
/** MAIN PAGES */
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route:"/dashboard",
    component:<Dashboard />,
    icon: <ViewQuiltIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Offers",
    key: "offers",
    route:"/offers",
    component:<Offers />,
    icon: <LocalOfferIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    route:"/users",
    component:<Users />,
    icon: <GroupsIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Analytics",
    key: "analytics",
    route:"/analytics",
    component:<Analytics />,
    icon: <SignalCellularAltRoundedIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Our Stores",
    key: "our-stores",
    route:"/our-stores",
    component:<Stores />,
    icon: <Shop size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Our Brands",
    key: "our-brands",
    route:"/our-brands",
    component:<Brands />,
    icon: <DiscountIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Our Branches",
    key: "our-branches",
    route:"/our-branches",
    component:<Branch />,
    icon: <DeviceHubIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Notification",
    key: "notification",
    route:"/notification",
    component:<Notification />,
    icon: <NotificationsIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    route:"/settings",
    component:<Settings />,
    icon: <SettingsIcon size="12px" />,
    noCollapse: true,
  },
/** CREATE PAGES */
  {
    name: "New Offers",
    key: "new-offers",
    route:"/new-offers",
    component:<NewOffers />,
  },
  {
    name: "New Users",
    key: "new-users",
    route:"/new-users",
    component:<NewUsers />,
  },
  {
    name: "Loyalty Card",
    key: "loyalty-card",
    route:"/loyalty-card",
    component:<LoyaltyCardDetails />,
  }, 
  {
    name: "New Store",
    key: "new-store",
    route:"/new-store",
    component:<NewStore />,
  },
  {
    name: "New Brand",
    key: "new-brand",
    route:"/new-brand",
    component:<NewBrand />,
  },
  {
    name: "New Branch",
    key: "new-Branch",
    route:"/new-branch",
    component:<NewBranch />,
  },
  {
    name: "New Notification",
    key: "new-notification",
    route:"/new-notification",
    component:<NewNotification />,
  },
/** EDIT PAGES */
  {
    name: "Edit Offer",
    key: "new-offer",
    route:"/edit-offer",
    component:<EditOffer />,
  },
  {
    name: "Edit User",
    key: "new-user",
    route:"/edit-user",
    component:<EditUser />,
  },
  {
    name: "Edit Store",
    key: "new-store",
    route:"/edit-store",
    component:<EditStore />,
  },
  {
    name: "Edit Brand",
    key: "new-brand",
    route:"/edit-brand",
    component:<EditBrand />,
  },
  {
    name: "Edit Branch",
    key: "new-branch",
    route:"/edit-branch",
    component:<EditBranch />,
  },
  {
    name:"Edit Notification",
    key:"edit-notification",
    route:"/edit-notification",
    component:<EditNotification />,
  },
  {
    name: "Privacy Cookie Policy",
    key: "privacy-cookie-policy",
    route:"/privacy-cookie",
    component:<PrivacyCookiePolicy />,
  },
  {
    name: "Privacy Policy",
    key: "privacy-policy",
    route:"/privacy-policy",
    component:<PrivacyPolicy />,
  },
  {
    name: "Term and Conditions",
    key: "terms-and-conditions",
    route:"/terms-and-conditions",
    component:<TermAndConditions />,
  },
  {
    name: "About us",
    key: "about-us",
    route:"/about-us",
    component:<AboutUs />,
  },
  {
    name: "About Shuzgroup",
    key: "about-shuzgroup",
    route:"/",
    component:<AboutShuzgroup />,
  },
/** AUTHENTICATION PAGES */
  {
    name: "Sign in",
    key: "sign-in",
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    name: "Sign up",
    key: "sign-up",
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    name: "Reset password",
    key: "reset-password",
    route: "/authentication/reset-password/illustration",
    component: <ResetIllustration />,
  },
  {
    name: "Confirm Password",
    key: "confirm-password",
    route: "/authentication/reset-password/confirm-password",
    component: <ConfirmPassword />,
  },
  {
    name: "verification",
    key: "verification",
    route: "/authentication/verification/illustration",
    component: <VerificationIllustration />,
  },
  {
    name: "Error 404",
    key: "error-404",
    route: "/authentication/error/404",
    component: <Error404 />,
  },
  {
    name: "Error 500",
    key: "error-500",
    route: "/authentication/error/500",
    component: <Error500 />,
  },
/**
  {
    name: "Illustration",
    key: "illustration",
    route: "/authentication/lock/illustration",
    component: <LockIllustration />,
  },
  { type: "divider"},
  { type: "title", title: "Pages", key: "title-pages" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Shop size="12px" />,
    collapse: [
      {
        name: "Default",
        key: "default",
        route: "/dashboards/default",
        component: <Default />,
      },
    ],
  },
*/
];

export default routes;
