/* eslint-disable react/prop-types */
// import hooks
import { useEffect, useState, useMemo } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
import $ from "jquery";

// Soft UI Dashboard PRO React example components
import DataTable from 'react-data-table-component';

// BrandList page components
import IdCell from "layouts/brands/components/IdCell";
import DefaultCell from "layouts/brands/components/DefaultCell";
import NumberCell from "layouts/brands/components/NumberCell";
import StatusCell from "layouts/brands/components/StatusCell";
import ActionCell from "layouts/brands/components/ActionCell";

function BrandTable(){
  
  const [datas, setDatas] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [rowTotal, setRowTotal] = useState(1);
  const [sort, setSort] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSearch = async (e) => {
    let searchVal = $(".searchVal").val();
    setSearch(searchVal);
  };

  const handlePerPage = async (e) => {
    setPerPage(e)
  };

  const handleSort = (column, sortDirection) => {
    let word = String(column.selector);
    word = word.replaceAll("row => row.","");
    setTimeout(() => {
      setSort([word,sortDirection])
    }, 100);
  };

  const listAllBrands = async () => {

    setLoading(true);
    let current = new Date();
    
    const fromDate = "01/01/2000";
    const toDate = current.toLocaleDateString('en-GB');

    try{
      const response = await axios
      .get(`${ApiRequests.listAllBrands}?fromDate=${fromDate}&pageNo=${page}&toDate=${toDate}&pageSize=${perPage}&searchQuery=${search}&sort=${sort}`)
      .then((response) => {
        setDatas(response.data.brands)
        let pageTotal = Math.ceil(Number(response.data.metadata.total) / perPage);
        setRowTotal(pageTotal);
        setLoading(false);
        setTimeout(function(){
          let el = $(".rdt_Pagination").find("span");

          el.each(function () {
            if ($(this).text().includes("of")) {
              $(this).html(page + " of " + pageTotal);
            }
          });
          
        },500);
    });
    }catch(err){
      if(err.response){
        //Not in the 200 response range.
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      }
      else{
        console.log(`Error: ${err.message}`);
      }
    }
  };

  useEffect(() => {  
    listAllBrands();

  }, [page, search, perPage, sort]);

  const columns = [
    { 
      name: "Title",
      selector: row => row.title,
      cell: (value) => <IdCell obj={value} />,
      sortable:true
    },
    {
      name: "Body",
      selector: row => row.description,
      width:"58%",
      cell: (value) => <DefaultCell value={value.description} />,
      sortable:true
    },
    {
      name: "Active/Inactive",
      selector: row => row.active,
      width:"12%",
      cell: (row) => <StatusCell obj={row} checked={row.active} />,
      sortable:true
    },
    { 
      name: "Action", 
      selector: row => row.action, 
      width:"12%", 
      cell:(row)=><ActionCell obj={row}/> 
    },
  ];

  return (
    <>
      <br/>
      <div className="container">
      <div className="row">
        <div className="col-md-9">
        </div>
        <div className="col-md-3">
          <div className="input-group mb-3">
             <input style={{"marginRight": "10px"}} className="searchVal form-control" placeholder="Search ..."/>
            <div className="input-group-append">
              <button style={{"background": "black","color": "white"}} className="input-group-text" onClick={handleSearch}>Search</button>
            </div>
          </div>
        </div>
      </div>
      </div>
      <DataTable
        columns={columns}
        data={datas}
        progressPending={loading}
        highlightOnHover
        pagination
        paginationServer
        paginationTotalRows={rowTotal}
        paginationPerPage={page}
        onChangePage={page => setPage(page)}
        onChangeRowsPerPage={handlePerPage}
        sortServer
        onSort={handleSort}
      />
    </>
  );
}
export default BrandTable;