/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-router-dom components
import { Link, Route, Routes } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';


// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Images
import ShuzGroupEffect from "assets/images/shuzgroup-skechers-effect-img.png";
import LocationSvg from "assets/images/mark-location-svg.svg";
import EmailSvg from "assets/images/email-svg.svg";
import MobileSvg from "assets/images/mobile-svg.svg";

function Footer() {
  return (
    <SoftBox component="footer" bgColor="#fff" pb={5} id="contact-section">
      <SoftBox
        component="img"
        src={ShuzGroupEffect}
        width="100vw"
        height="100%"
        overflow="hidden"
        pb={6}
      />
      <Grid container justifyContent="center" pb={6}>
        <Grid item xs={12} lg={8}>
          <SoftBox textAlign="center">
            <SoftTypography variant="h3" fontWeight="regular" sx={{color:"#000"}}>
              Contact Us
            </SoftTypography>
          </SoftBox>
        </Grid>
      </Grid>
      <Grid container spacing={{ xs:2, md:2, lg:1, xl:0 }} justifyContent="center" alignItems="flex-start" pb={8} className="contact-info">
        <Grid item xs={12} md={6} lg={2}>
          <Grid container spacing={0} justifyContent="center" alignItems="center">
            <Grid item>
              <SoftBox textAlign="center">
                <SoftBox
                  component="img"
                  src={LocationSvg}
                  mb={2}
                />
                <SoftTypography mb={1} variant="h4" fontWeight="regular" sx={{color:"#000"}}>
                  Address
                </SoftTypography>
                <SoftTypography variant="body 2" fontWeight="regular" sx={{color:"#000"}}>
                  Unit 15, Euro Innovation Park, Little Island, Cork, T45 W328
                </SoftTypography>
              </SoftBox>
            </Grid>
          </Grid>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={12} md={6} lg={2}>
          <Grid container spacing={0} justifyContent="center" alignItems="center">
            <Grid item>
              <SoftBox textAlign="center">
                <SoftBox
                  component="img"
                  src={EmailSvg}
                  mb={2}
                />
                <SoftTypography mb={1} variant="h4" fontWeight="regular" sx={{color:"#000"}}>
                  Email Address
                </SoftTypography>
                <SoftTypography component="a" href="mailto:customerservice@shuzgroup.ie" variant="body 2" fontWeight="regular" sx={{color:"#000"}}>
                  customerservice@shuzgroup.ie
                </SoftTypography>
              </SoftBox>
            </Grid>
          </Grid>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={12} md={6} lg={2}>
          <Grid container spacing={0} justifyContent="center" alignItems="center">
            <Grid item>
              <SoftBox textAlign="center">
                <SoftBox
                  component="img"
                  src={MobileSvg}
                  mb={2}
                />
                <SoftTypography mb={1} variant="h4" fontWeight="regular" sx={{color:"#000"}}>
                  Phone Mobile
                </SoftTypography>
                <SoftTypography component="a" href="tel:0214520008" variant="body 2" fontWeight="regular" sx={{color:"#000"}}>
                  +353 (0) 21 452 0008
                </SoftTypography>
              </SoftBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" spacing={0.5} alignItems="center" className="copyright-section">
        <Grid item xs={12} md={6} lg={3}>
          <SoftBox textAlign="center">
            <SoftTypography variant="body 2" fontWeight="regular" sx={{color:"#000"}}>
              &copy; {new Date().getFullYear()} Shuz4U Distribution Ltd.
            </SoftTypography>
          </SoftBox>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <SoftBox textAlign="center">
            <Link to="/privacy-policy">
              <SoftTypography variant="body 2" fontWeight="regular" sx={{color:"#000"}}>
                Privacy Policy
              </SoftTypography>
            </Link>
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default Footer;
