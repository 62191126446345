
// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// ContactInfo page components
import FormField from "layouts/new-users/components/FormField";
import PropTypes from "prop-types";

function ContactInfo({data, setData}) {
  
  const handleInput = (event) => {
    setData({...data, [event.target.name]: event.target.value});
  };

  return (
    <SoftBox>
      <SoftTypography variant="h3" fontWeight="medium">
        Contact Information
      </SoftTypography>
      <SoftBox mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormField onChange={handleInput} name="address" type="text" label="Address*" value={data?.address} placeholder="Address" required/>
          </Grid>
          <Grid item xs={12}>
            <FormField onChange={handleInput} name="phoneNo" type="tel" label="Phone Number" value={data?.phoneNo} placeholder="Phone Number" />
          </Grid>
          <Grid item xs={12}>
            <FormField onChange={handleInput} name="branchName" type="text" label="Branch Name*" value={data?.branchName} placeholder="Branch Name" required/>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

ContactInfo.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
};

export default ContactInfo;
