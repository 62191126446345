/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for type checking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

function Template({ action }) {
  return(
    <SoftButton variant="gradient" color="warning" onClick={action}>
      create
    </SoftButton>
  );
}

// Typechecking props for the Template
Template.propTypes = {
  action: PropTypes.func.isRequired,
};

export default Template;
