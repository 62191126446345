// import hooks.
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
import { useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// sweetalert2 components
import Swal from "sweetalert2";

// Sweet Alerts page components
import Template from "layouts/authentication/sign-in/alerts/Template";

function AuthSignIn({ postEmail, postPwd }) {
  const navigate = useNavigate();
  const signIn = async(event) => {
    event.preventDefault();
    //console.log(postEmail," ", postPwd);
    //await axios.get(`${ApiRequests.adminSignIn}`, {}, {params:{postEmail, postPwd}})
    await axios.get(`${ApiRequests.adminSignIn}/${postEmail}/${postPwd}`)
    .then((response) => {
      if(response?.status === 400 || response?.data == "EMAIL_NOT_FOUND"){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: 'Invalid User!<br /><br /><strong>Please contact support.</strong>',
          footer: response.data,
          type:"error",
        })
      }
      else if(response?.status === 400 || response?.data == "INVALID_PASSWORD"){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: 'Invalid User!<br /><br /><strong>Please contact support.</strong>',
          footer: response.data,
          type:"error",
        })
      }
      /// successful signin
      else if(response.status === 200){
        localStorage.setItem("user_login",JSON.stringify(response.data));
        //window.location.href = "/dashboard";
        window.location.href = "/dashboard";
      }      
    }).catch((err)=>{
      if(err?.response){
        //Not in the 200 response range.
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: 'Something went wrong!<br /><br /><strong>Please contact support.</strong>',
          footer: err.response.data.message,
          type:"error",
        })
      }
      else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: 'Something went wrong!<br /><br /><strong>Please contact support.</strong>',
          footer: err.message,
          type:"error",
        })
      }
    });
  };

  return (
    <Template
      action={signIn}
    />
  );
}

AuthSignIn.propTypes = {
  postEmail: PropTypes.string,
  postPwd: PropTypes.string,
};

export default AuthSignIn;
