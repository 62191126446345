// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import DeleteData from "layouts/stores/alerts/DeleteData";

function ActionCell({obj}) {
  const storeId = obj.storeId;
  return (
    <SoftBox display="flex" alignItems="center" gap="16px">
      <Link to={`/edit-store?storeId=${storeId}`}>
        <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Preview Store" placement="top">
            <Icon>visibility</Icon>
          </Tooltip>
        </SoftTypography>
      </Link>
      <DeleteData storeId={storeId}/>
    </SoftBox>
  );
}
// Typechecking props for the ActionCell
ActionCell.propTypes = {
  //id: PropTypes.string,
  obj: PropTypes.object,
};
export default ActionCell;
