// import library/hooks
import axios from "axios";

const auth_token = 'Bearer_eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTIzNDU2';
/** initialize baseUrl to make a requests to the APIs */
const instance = axios.create({
    baseURL: 'https://www.shuzgroup.com/sb',
    //baseURL: 'https://shuzgroup.com/sb',
    headers:{
        Authorization: `${auth_token}`,
    },
});
export default instance;