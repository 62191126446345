// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftDatePicker from "components/SoftDatePicker";

// NewOffer page components
import FormField from "layouts/new-offers/components/FormField";

function OfferInfo({ postData, setPostData }) {

  const handleInput = (event) => {
    setPostData({...postData, [event.target.name]: event.target.value});
  };
  const handleInputStartDate = (selectedDates, dateStr, instance) => {
    //console.log(dateStr);
    setPostData({...postData, "startDate": dateStr});
  };
  const handleInputEndDate = (selectedDates, dateStr, instance) => {
    setPostData({...postData, "endDate": dateStr});
  };
/*
  const handleInput = (input) => (event) => {
    setPostData({...postData, [input]: event.target.value});
  };
*/
  return (
    <SoftBox>
      <SoftTypography variant="h3" fontWeight="medium">Offer Information</SoftTypography>
      <SoftBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField value={postData?.title} onChange={handleInput} name="title" type="text" label="title*" placeholder="Title*" required />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField value={postData?.offerScope} onChange={handleInput} name="offerScope" type="text" label="offer* (max. 25 characters)" placeholder="" required />
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="button" fontWeight="regular">
                Description*&nbsp;&nbsp;
              </SoftTypography>
            </SoftBox>
            <SoftInput value={postData?.description} onChange={handleInput} name="description" placeholder="Description*" multiline rows={5} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={3}>
              <FormField value={postData?.prevPrice} onChange={handleInput} name="prevPrice" type="text" label="Previous price (only active if used)" required />
            </SoftBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="block">
                  <SoftTypography component="label" variant="button" fontWeight="regular">
                    Start Date*&nbsp;&nbsp;
                  </SoftTypography>
                  {/*<SoftDatePicker onChange={handleInputStartDate} options={{dateFormat:"d/m/Y"}} input={{ placeholder: "Select a date" }} />*/}
                  <SoftDatePicker onChange={handleInputStartDate} options={{dateFormat:"d/m/Y H:i:S"}} input={{ placeholder: "Select a date" }} />
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="block">
                  <SoftTypography component="label" variant="button" fontWeight="regular">
                    End Date*&nbsp;&nbsp;
                  </SoftTypography>
                  {/*<SoftDatePicker onChange={handleInputEndDate} options={{dateFormat:"d/m/Y"}} input={{ placeholder: "Select a date" }} />*/}
                  <SoftDatePicker onChange={handleInputEndDate} options={{dateFormat:"d/m/Y H:i:S"}} input={{ placeholder: "Select a date" }} />
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

OfferInfo.propTypes = {
  postData: PropTypes.object,
  setPostData: PropTypes.func,
};

export default OfferInfo;
