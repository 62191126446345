// import hooks.
import { useEffect, useState } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
// react-router-dom components
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// EditBrand page components
import BrandInfo from "layouts/edit-brand/components/BrandInfo";
import BrandImage from "layouts/edit-brand/components/BrandImage";
import SaveChanges from "layouts/edit-brand/alerts/SaveChanges";
import ContinueSaveAlert from "layouts/edit-brand/alerts/ContinueSaveAlert";
//import BrandForm from "layouts/edit-brand/components/BrandForm";

function EditBrand() {
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  let brandId = params.get('brandId');
  const currentDate = new Date().toLocaleDateString();
  const [postData, setPostData] = useState({
    active:true,
    brandId:`${brandId}`,
    brandImageUrl:"",
    createdDate:`${currentDate}`,
    description: "",
    title: "",
  });

  const readBrand = async () => {
    try{
        const response = await axios
        .get(`${ApiRequests.readBrand+brandId}`)
        //.get(`${ApiRequests.readBrand}`)
        .then((response) => {
        setPostData(response.data)
        //console.log(response)
      });
    }catch(err){
      if(err.response){
      //Not in the 200 response range.
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
      }
      else{
      console.log(`Error: ${err.message}`);
      }
    }
  };
/// tell React that your component needs to do something after render.
  useEffect(() => {  
    readBrand();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <SoftTypography variant="h2" fontWeight="regular">
                Make the changes below
              </SoftTypography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                  <ContinueSaveAlert />
                <SoftBox ml={1}>
                  <SaveChanges postData={postData} />
                </SoftBox>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
{/*       <BrandForm postData={postData} setPostData={setPostData} /> */}
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <BrandImage postData={postData} setPostData={setPostData} />       
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={8}>
            <BrandInfo postData={postData} setPostData={setPostData} />
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditBrand;
