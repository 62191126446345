// import hooks.
import { useEffect, useState } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
const defaultImage = "";

function FeaturedImage({ postData, setPostData }) {

  const handleRemoveFeaturedImg = (event) => {
    setPostData({...postData, "featuredImageURL": defaultImage})
  };

  const handleFeaturedImg = (files) => {
    files.map(file =>
      setPostData({...postData, "featuredImageURL": file.path})
    );
  };

  const handleDropzone = (event) =>{
    const imgPath = event.target.value;
    const img = imgPath.replace(/^.*[\\\/]/, '');

    let data = new FormData();
    data.append("file",event.target.files[0]);

    axios.post(`${ApiRequests.uploadFeatureImage}`, data).then((response) => {
      setPostData({...postData, "featuredImageURL": response.data[0]});
    }).catch((err) => {
      console.log(err);
    }); 
  };

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5" fontWeight="regular">
          Featured Image
        </SoftTypography>
        <SoftBox
          component="img"
          src={postData?.featuredImageURL}
          alt="Featured Image"
          borderRadius="lg"
          shadow="lg"
          width="40%"
          loading="lazy"
          my={3}
        />

        <SoftBox display="flex">
          <SoftBox mr={1}>
          <SoftBox 
            variant="gradient" 
            color="dark" 
            size="small" 
            label="edit" 
            component="input" 
            type="file" 
            name="featuredImageURL"
            accept="image/*" 
            onChange={handleDropzone} />

          </SoftBox>
          <SoftButton onClick={handleRemoveFeaturedImg} variant="outlined" color="dark" size="small">
            remove
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

FeaturedImage.propTypes = {
  postData: PropTypes.object,
  setPostData: PropTypes.func,
};

export default FeaturedImage;