// import hooks.
import { useEffect, useState } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftCustom from "components/SoftBox/SoftCustom";

// Images
import Sketchers from "assets/images/sketchers-content-edit-img.png";
const defaultImage = "";

function OfferImage({ postData, setPostData }) {

  const handleFeaturedImg = (files) => {
    files.map(file =>
      setPostData({...postData, "offerImageURLs": file.path})
    );
  };

  const handleRemoveFeaturedImg = (event) => {
    setPostData({...postData, "offerImageURLs": defaultImage})
  };

  const handleInput = (event) => {
    setPostData({...postData, [event.target.name]: event.target.value});
  };

  const handleOfferImg = (event) =>{
    let arr_img = [];

    let files = event.target.files

    let data = new FormData();
    for (var i=0;i<files.length;i++) {
      data.append("file",files[i]);
    }

    axios.post(`${ApiRequests.uploadOfferImage}`, data).then((response) => {
      setPostData({...postData, "offerImageURLs": response.data});
    }).catch((err) => {
      console.log(err);
    });
  };

/**
  const offerImages = array.forEach((imageUrls) => {
    data?.offerImageURLs.push(
      <SoftBox
        component="img"
        key={imageUrls}
        src={imageUrls}
        alt="Offer Image"
        borderRadius="lg"
        shadow="lg"
        width="40%"
        loading="lazy"
        wrapped
        ui={false}
        m={2}
      />
    )
  });
*/

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5" fontWeight="regular">
          Offer Images
        </SoftTypography>        
    
        <SoftCustom postData={postData}/>

        <SoftBox display="flex">
          <SoftBox mr={1}>
          <SoftBox 
            variant="gradient" 
            color="dark" 
            size="small" 
            label="edit" 
            component="input" 
            type="file" 
            name="offerImageURLs"
            accept="image/*"
            multiple
            onChange={handleOfferImg} />
{/*
            <SoftButton variant="gradient" color="dark" size="small">
              edit                  
            </SoftButton>
*/}
          </SoftBox>
          <SoftButton onClick={handleRemoveFeaturedImg} variant="outlined" color="dark" size="small">
            remove
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

OfferImage.propTypes = {
  postData: PropTypes.object,
  setPostData: PropTypes.func,
};

export default OfferImage;
