
import Dropzone from 'react-dropzone';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

function DropzoneComponent({ ...rest}){

    return(
        <>
            <Dropzone {...rest}>
                {({getRootProps, getInputProps}) => (
                <SoftBox {...getRootProps()} className="ctm-dropzone">
                    <SoftBox 
                        component="input" 
                        name="brandImageUrl" 
                        type="file"
                        accept="image/*"
                        {...getInputProps()} 
                    />
                    <SoftBox component="span">Drop image here to upload</SoftBox>
                </SoftBox>
                )}
            </Dropzone>
        </>
    );
}
export default DropzoneComponent;