// prop-types is a library for type checking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftButton from "components/SoftButton";

function Template({ action }) {
  return(
    <SoftButton variant="gradient" color="warning" size="large" fullWidth onClick={action}>
      sign in
    </SoftButton>
  );
}

// Typechecking props for the Template
Template.propTypes = {
  action: PropTypes.func.isRequired,
};

export default Template;
