// import hooks.
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
import $ from "jquery";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// sweetalert2 components
import Swal from "sweetalert2";

// Sweet Alerts page components
import Template from "layouts/edit-offer/alerts/SaveChanges/Template";

function SaveChanges({ postData }) {
  const navigate = useNavigate();
  const showAlert = (event) => {
    event.preventDefault();
    let notValid = 0;
/*    console.log(postData);*/

    let optionalField = ["prevPrice","offerImageURLs","featuredImageURL","ageRange","brand","notificationText"];

    $(".errCs").remove();
    $("input").each(function(i,obj){
      let attrid = $(obj).attr("id");
      let selection = false;  

      if (typeof attrid!=="undefined") {
        if (attrid.includes("react-select")) {
          selection = true;
        }
      }

      if (!selection) {
        if ($(obj).val()==""){
          if (!optionalField.includes($(obj).attr("name"))){
            if ($(obj).attr("placeholder")=="Select a date") {
              $(obj).parent().parent().parent().append("<span class='errCs'>Required!</span>");
            } else {
              $(obj).parent().parent().append("<span class='errCs'>Required!</span>");
            }
            notValid+=1;
          }
        }
      }
    })
    $("textarea").each(function(i,obj){
      if ($(obj).val()==""){
        if (!optionalField.includes($(obj).attr("name"))) {
          $(obj).parent().parent().append("<span class='errCs'>Required!</span>");
          notValid+=1;
        }
      }
    })

    if (notValid>0) {
      //
    } else {

      const newSwal = Swal.mixin({
        customClass: {
          confirmButton: "button btn-save button-success",
          cancelButton: "button btn-continue",
        },
        buttonsStyling: false,
      });
      newSwal
        .fire({
          title: "Save Changes?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Save",
          reverseButtons: true,
          cancelButtonText:"Cancel",
        })
        .then((result) => {
          if (result.isConfirmed) {

            if (postData.gender=="") {
              postData.gender = null;
            }

            if (postData.ageRange=="") {
              postData.ageRange = null;
            }

            if (postData.offerImageURLs=="") {
              postData.offerImageURLs = [];
            }

            if (postData.notificationCategory=="") {
              postData.notificationCategory = [];
            }

            axios.post(`${ApiRequests.updateOffer}`, postData)
            .then((response) => {
              Swal.fire({title:"Saved!", text:"Your changes have been saved.", type:"success", icon:"success"}).then(function(){
                navigate("/offers");
                //window.location.href = "/offers";
              });
            }).catch((err) => {
              Swal.fire({title:"Failed!", text:err.response.data.message, type:"error", icon:"error"})
                .then(function() {
                });
            });
          }
        });

    }
  };

  return (
    <Template
      action={showAlert}
    />
  );
}

SaveChanges.propTypes = {
  postData: PropTypes.object,
};

export default SaveChanges;
