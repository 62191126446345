// react-router-dom components
import { useNavigate } from "react-router-dom";

// sweetalert2 components
import Swal from "sweetalert2";

// Sweet Alerts page components
import Template from "layouts/new-offers/alerts/ContinueSaveAlert/Template";

function ContinueSaveAlert() {
  const navigate = useNavigate();
  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button btn-save button-success",
        cancelButton: "buttton btn-quit",
        //denyButton: "button btn-continue",
      },
      buttonsStyling: false,
    });
    newSwal
      .fire({
        title: "Continue or Quit",
        text: "Would you like to continue creating this offer or quit?",
        icon: "warning",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Continue",
        //confirmButtonText: "Save",
        //denyButtonText: "Continue",
        reverseButtons: true,
        cancelButtonText:"Quit",
      })
      .then((result) => {
        /*
        if (result.isConfirmed){
          Swal.fire({title:"Saved!", text:"Your progress has been saved.", type:"success", icon:"success"}).then(function(){
            navigate("/offers");
            //window.location.href = "/offers";
          });
        }else
         */
        if (result.dismiss === Swal.DismissReason.cancel){
          navigate("/offers");
          //window.location = "/offers";
        }
      });
  };

  return (
    <Template
      action={showAlert}
    />
  );
}

export default ContinueSaveAlert;
