// import hooks.
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// sweetalert2 components
import Swal from "sweetalert2";

// Sweet Alerts page components
import Template from "layouts/brands/alerts/DeleteData/Template";

function DeleteData({ brandId }) {
  const showAlert = (event) => {
    event.preventDefault();
    //console.log(brandId);
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button btn-save button-success",
        cancelButton: "button btn-continue",
      },
      buttonsStyling: false,
    });
    newSwal
      .fire({
        title: "Delete brand?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        reverseButtons: true,
        cancelButtonText:"Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios.post(`${ApiRequests.removeBrand}`, {brandId})
          .then((response) => {
            Swal.fire({title:"Deleted!", text:"Brand has been deleted.", type:"success", icon:"success"}).then(function(){
              window.location.reload();
            });
          }).catch((err) => {
            Swal.fire({title:"Failed!", text:err.response.data.message, type:"error", icon:"error"})
              .then(function() {
              });
          });
        }
      });

  };

  return (
    <Template
      action={showAlert}
    />
  );
}

DeleteData.propTypes = {
  brandId: PropTypes.string,
};

export default DeleteData;
