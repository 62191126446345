import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftEditor from "components/SoftEditor";
import SoftSelect from "components/SoftSelect";
import SoftDatePicker from "components/SoftDatePicker";

// BasicInfo page components
import FormField from "layouts/new-users/components/FormField";
import PropTypes from "prop-types";

function BasicInfo({data,setData}) {
  const [editorValue, setEditorValue] = useState(
    "<p>Description</p><br><br><br>"
  );

  const titleOption = [
    { value: "Mr", label: "Mr" },
    { value: "Ms", label: "Ms" },
    { value: "Mrs", label: "Mrs" },
  ];
  const genderOption = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "others", label: "Others" },
  ];

  const handleInput = (event) => {
    setData({...data, [event.target.name]: event.target.value});
  };

  const handleSelectTitle = (option) => {
    setData({...data, "title": option.value});
  };

  const handleSelectGender = (option) => {
    setData({...data, "gender": option.value});
  };

  const handleInputDob = (selectedDates, dateStr, instance) => {
    setData({...data, "dob": dateStr});
  };

  return (
    <SoftBox>
      <SoftTypography variant="h3" fontWeight="medium">Basic Information</SoftTypography>
      <SoftBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField placeholder="Email" name="email" type="email" onChange={handleInput} label="email*" value={data?.email} required/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  Title
                </SoftTypography>
              </SoftBox>
              <SoftSelect id="title"
                options={titleOption}
                value={titleOption.find(({value}) => value === data?.title)}
                onChange={handleSelectTitle}
              />
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField placeholder="First Name" name="firstName" type="text" label="first name*" onChange={handleInput} value={data?.firstName} required/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField placeholder="Last Name" name="lastName" type="text" label="last name*" onChange={handleInput} value={data?.lastName} required/>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  Gender
                </SoftTypography>
              </SoftBox>
              <SoftSelect
                options={genderOption}
                value={genderOption.find(({value}) => value === data?.gender)}
                onChange={handleSelectGender}
              />
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography id="dob" component="label" variant="button" fontWeight="regular">
                  DOB*
                </SoftTypography>
              </SoftBox>
              {/*<SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">*/}
                <SoftDatePicker value={data?.gender} onChange={handleInputDob} options={{dateFormat:"d/m/Y H:i:S"}} input={{ placeholder: 'Date of Birth' }} />
              {/*</SoftBox>*/}
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

BasicInfo.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
};

export default BasicInfo;
