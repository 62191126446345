// import hooks.
import { useEffect, useState } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
//import { DropzoneArea } from 'material-ui-dropzone';
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftDropzone from "components/SoftDropzone";
import DropzoneComponent from "components/DropzoneComponent";
import DropzoneAreaComponent from "components/DropzoneAreaComponent";
import SoftButton from "components/SoftButton";

// BrandInfo page components
///import FormField from "layouts/ecommerce/products/new-product/components/FormField";
import FormField from "layouts/new-brand/components/FormField";
import CancelFormAlert from "layouts/new-brand/alerts/CancelFormAlert";
import CreateSaveAlert from "layouts/new-brand/alerts/CreateSaveAlert";

function BrandInfo() {
  //const currentDate = new Date().toLocaleDateString(); //just date.
  const currentDate = new Date().toLocaleString('en-GB', {
    hour12: false,
  }).replace(',','');
  const randId = btoa(Math.random()).slice(0, 13);
  const [image, setImage] = useState(null);
  const [postData, setPostData] = useState({
    active:true,
    //brandId:`${randId}`,
    brandId:"",
    brandImageUrl:"",
    createdDate:`${currentDate}`,
    description: "",
    title: "",
  });
  
  const handleInput = (event) => {
    setPostData({...postData, [event.target.name]: event.target.value});
  };
/* 
  const handleDropzone = (files) => {
    //console.log(files);
    const imgPath = files.map(file => (file.path));
    console.log(imgPath);
    //setPostData({...postData, "brandImageUrl": imgPath});
    files.map(file =>
      setPostData({...postData, "brandImageUrl": file.path})
      );
    };

    
    let handleDropzone = (acceptedFiles) => {
      const imgPath = acceptedFiles.map(file => file.path);
      acceptedFiles.map(file =>
        setPostData({...postData, "brandImageUrl": file.path})
        );
        console.log(acceptedFiles);
      };
      */
   const handleDropzone = (event) =>{
    //console.log(event.target.value);
    const imgPath = event.target.value;
    const img = imgPath.replace(/^.*[\\\/]/, '');

    let data = new FormData();
    data.append("file",event.target.files[0]);

    axios.post(`${ApiRequests.uploadBrandImage}`, data).then((response) => {
      setPostData({...postData, "brandImageUrl": response.data});
    }).catch((err) => {
      console.log(err);
    }); 
   };
   return (
    <SoftBox>
      <SoftTypography variant="h3" fontWeight="medium">Brand Information</SoftTypography>
        <SoftBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField type="text" label="title*" placeholder="Title*" onChange={handleInput} name="title" />
            </Grid>
            <Grid item xs={12}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="block">
                <SoftTypography component="label" variant="button" fontWeight="regular" className="lbl-txt">
                  Description*&nbsp;&nbsp;
                </SoftTypography>
              </SoftBox>
              <SoftInput placeholder="Description*" multiline rows={5} onChange={handleInput} name="description" />
            </Grid>
            <Grid item xs={12}>
              <SoftBox>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography component="label" variant="button" fontWeight="regular" className="lbl-txt">
                    Brand Image
                  </SoftTypography>
                </SoftBox>
                <SoftBox component="div">
                  <SoftBox component="label" className="lbl-file-type">
                    Insert image here to upload
                    <SoftBox 
                      component="input" 
                      name="brandImageUrl" 
                      type="file"
                      accept="image/*"
                      className="input-file-type" 
                      onChange={handleDropzone}
                    />
                  </SoftBox>
                </SoftBox>
{/*
                <DropzoneComponent onDrop={handleDropzone} />
                <SoftBox 
                      component="input" 
                      name="brandImageUrl" 
                      type="file"
                      accept="image/*"
                      onChange={handleDropzone}
                ></SoftBox>
                <DropzoneArea
                  dropzoneClass="dropzone-area"
                  onAdd={handleDropzone}
                  acceptedFiles={['image/*']}
                  dropzoneText="Drop image here to upload"
                  showAlerts={false}
                />
                <SoftDropzone onChange={handleDropzone} options={{ addRemoveLinks: true }} />
*/}
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox display="flex" justifyContent="flex-end" mt={3}>
          <SoftBox mr={2}>
            <CancelFormAlert />
          </SoftBox>
          <CreateSaveAlert postData={postData}/>
        </SoftBox>
    </SoftBox>
  );
}

export default BrandInfo;
