// import hooks.
import { useEffect, useState } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";

// react-router-dom components
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// sweetalert2 components
import Swal from "sweetalert2";
import $ from "jquery";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// StoreInfo page components
///import FormField from "layouts/ecommerce/products/new-product/components/FormField";
import FormField from "layouts/new-store/components/FormField";
import CancelFormAlert from "layouts/new-store/alerts/CancelFormAlert";
import CreateSaveAlert from "layouts/new-store/alerts/CreateSaveAlert";

function isNumeric(str) {
  if (typeof str != "string") return false
  return !isNaN(str) &&
         !isNaN(parseFloat(str))
}

let minAddress = 4;

function StoreInfo() {
  const navigate = useNavigate();
  
  const handleLatLong = () => {

    if ($("#address").val().length>=minAddress) {

      axios({
        method: "get",
        url: "https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input="+$("#address").val()+"&inputtype=textquery&fields=formatted_address%2Cname%2Crating%2Copening_hours%2Cgeometry&key=AIzaSyBkeG7frURjx8GJFtZD-sCTncmr-cJqinc",
      }).then(function (response) {
        if (response.data.status=="OK") {
          if (response.data.candidate) {
            let lat = response.data.candidate[0].geometry.location.lat;
            let long = response.data.candidate[0].geometry.location.lng;
            $("#latlong").val(lat+", "+long);
          }
        }
      }).catch(function (error) {
        console.log(error)
      });

    }

  }

  const handleCreate = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button btn-save button-success",
        cancelButton: "buttton btn-quit",
     },
      buttonsStyling: false,
    });

    let notValid = 0;

    let optionalField = [];

    $(".errCs").remove();
    $("input").each(function(i,obj){
      let attrid = $(obj).attr("id");
      let selection = false;  

      if (typeof attrid!=="undefined") {
        if (attrid.includes("react-select")) {
          selection = true;
        }
      }

      if (!selection) {
        if ($(obj).val()==""){
          if (!optionalField.includes($(obj).attr("name"))){
            if ($(obj).attr("placeholder")=="Select a date") {
              $(obj).parent().parent().parent().append("<span class='errCs'>Required!</span>");
            } else {
              $(obj).parent().parent().append("<span class='errCs'>Required!</span>");
            }
            notValid+=1;
          }
        }
      }

      if (attrid=="phone_no") {
        if (!isNumeric($(obj).val())){
          $(obj).parent().parent().append("<span class='errCs'> Please input numbers only!</span>");
          notValid+=1;
        }
      }

    })

    if ($("#address").val().length<minAddress){
      $($("#address")).parent().parent().append("<span class='errCs'> Address minimal 4 digit!</span>");
      notValid+=1;
    }

    if (!$("#latlong").val().includes(",")){
      $($("#latlong")).parent().parent().append("<span class='errCs'> Please separate with comma for lattitude and longitude!</span>");
      notValid+=1;
    }

    $("textarea").each(function(i,obj){
      if ($(obj).val()==""){
        if (!optionalField.includes($(obj).attr("name"))) {
          $(obj).parent().parent().append("<span class='errCs'>Required!</span>");
          notValid+=1;
        }
      }
    })

    if (notValid==0) {

      newSwal
      .fire({
        title: "Create or Save",
        text: "Would you like to create this store or quit?",
        icon: "question",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Create",
        reverseButtons: true,
        cancelButtonText:"Quit",        
      })
      .then((result) => {
/*
        if (result.isConfirmed) {
          Swal.fire({title:"Saved!", text:"Your progress has been saved.", type:"success", icon:"success"}).then(function(){
            window.location.href = "/our-stores";
          });
        }
        else if (result.isDenied) {
*/
        if (result.isConfirmed) {
          var timestamp = new Date();

          let d = timestamp.getDate()
          let m = timestamp.getMonth() + 1;
          let y = timestamp.getFullYear();

          var seconds = timestamp.getSeconds();
          var minutes = timestamp.getMinutes();
          var hour = timestamp.getHours();

          if (d>9) {
            d = d;
          } else {
            d = "0" + d;
          }

          if (m>9) {
            m = m;
          } else {
            m = "0" + m;
          }

          let createdDate = d + "/" + m + "/" + y + " " + hour + ":" + minutes + ":" + seconds;

          let reqlat = $("#latlong").val().split(",");

          let bodyFormData = {
            active:true,
            address: $("#address").val(),
            description: $("#description").val(),
            phoneNo: $("#phone_no").val(),
            title: $("#title").val(),
            latlong: {
              latitude: reqlat[0].replace(/ /g,""),
              longitude: reqlat[1].replace(/ /g,"")
            },
            createdDate: createdDate
          }
          
          axios({
            method: "post",
            url: ApiRequests.createStore,
            data: bodyFormData,
          }).then(function (response) {
            Swal.fire({title:"Created!", text:"Store has been created.", type:"success", icon:"success"}).then(function(){
              navigate("/our-stores");
              //window.location.href = "/our-stores";
            });
          }).catch(function (error) {
            Swal.fire({title:"Failed!", text:error.response.data.message, type:"error", icon:"error"}).then(function(){
              
            });
          });
         
        }
        else if (result.dismiss === Swal.DismissReason.cancel) {
          navigate("/our-stores");
          //window.location.href = "/our-stores";
        }
      });

    }
  };
  return (
    <SoftBox>
      <SoftTypography variant="h3" fontWeight="medium">Store Information</SoftTypography>
      <SoftBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField id="title" type="text" label="title*" placeholder="Title" required/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField id="phone_no" type="tel" label="telephone*" placeholder="Telephone" required/>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField id="address" type="text" label="address*" placeholder="Address" required/>
          </Grid>
          <Grid item xs={12}>
            <FormField id="latlong" type="text" label="Lat/Long*" placeholder="38.8951,-77.0364" required/>
          </Grid>
          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="block">
              <SoftTypography component="label" variant="button" fontWeight="regular">
                Description*&nbsp;&nbsp;
              </SoftTypography>
            </SoftBox>
            <SoftInput id="description" placeholder="Description" multiline rows={5} />
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox display="flex" justifyContent="flex-end" mt={3}>
        <SoftBox mr={2}>
          <CancelFormAlert />     
        </SoftBox>
          <SoftButton variant="gradient" color="warning" onClick={handleCreate}>
            create
          </SoftButton>
          {/**<CreateSaveAlert />*/}
      </SoftBox>
    </SoftBox>
  );
}

export default StoreInfo;
