//import hooks.
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";

// sweetalert2 components
import Swal from "sweetalert2";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// Sweet Alerts page components
import Template from "layouts/edit-notification/alerts/SaveChanges/Template";

import $ from "jquery";

function SaveChanges({ postData }) {
  const navigate = useNavigate();
  const showAlert = (event) => {
    event.preventDefault();
/*    console.log(postData);*/

    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button btn-save button-success",
        cancelButton: "button btn-continue",
      },
      buttonsStyling: false,
    });

    let notValid = 0;

    let optionalField = [];

    $(".errCs").remove();
    $("input").each(function(i,obj){
      let attrid = $(obj).attr("id");
      let selection = false;  

      if (typeof attrid!=="undefined") {
        if (attrid.includes("react-select")) {
          selection = true;
        }
      }

      if (!selection) {
        if ($(obj).val()==""){
          if (!optionalField.includes($(obj).attr("name"))){
            if ($(obj).attr("placeholder")=="Select a date") {
              $(obj).parent().parent().parent().append("<span class='errCs'>Required!</span>");
            } else {
              $(obj).parent().parent().append("<span class='errCs'>Required!</span>");
            }
            notValid+=1;
          }
        }
      }
    })

    $("textarea").each(function(i,obj){
      if ($(obj).val()==""){
        if (!optionalField.includes($(obj).attr("name"))) {
          $(obj).parent().parent().append("<span class='errCs'>Required!</span>");
          notValid+=1;
        }
      }
    })

    if (notValid==0) {

      newSwal
      .fire({
        title: "Save Changes?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Save",
        reverseButtons: true,
        cancelButtonText:"Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios.post(`${ApiRequests.updateNotif}`, postData)
          .then(function (response) {
            Swal.fire({title:"Saved!", text:"Your notification has been saved.", type:"success", icon:"success"}).then(function(){
              navigate("/notification");
            //window.location.href = "/notification";
            });
          }).catch(function (error) {
            Swal.fire({title:"Failed!", text:error.response.data.message, type:"error", icon:"error"}).then(function(){
            });
          });
        }
      });

    }

  };

  return (
    <Template
      action={showAlert}
    />
  );
}

SaveChanges.propTypes = {
  postData: PropTypes.object,
};

export default SaveChanges;
