import { useEffect,useState } from "react";
// react-router-dom components
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

// sweetalert2 components
import Swal from "sweetalert2";
import axios from "AxiosModule";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewUsers page components
import BasicInfo from "layouts/new-users/components/BasicInfo";
import ContactInfo from "layouts/new-users/components/ContactInfo";
import ContinueSaveAlert from "layouts/new-users/alerts/ContinueSaveAlert";
import ApiRequests from "ApiRequests";
import { useLocation } from "react-router-dom";
import $ from "jquery";

function isNumeric(str) {
  if (typeof str != "string") return false
  return !isNaN(str) &&
         !isNaN(parseFloat(str))
}

function NewUsers() {

  function getSteps() {
    return ["1. Basic Info", "2. Contact Info"];
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <BasicInfo data={data} setData={setData}/>;
      case 1:
        return <ContactInfo data={data} setData={setData}/>;
      default:
        return null;
    }
  }

  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const handleNext = function(){
    let notValid = 0;

    let optionalField = ["title","gender","phoneNo"];

    $(".errCs").remove();
    $("input").each(function(i,obj){
      let attrid = $(obj).attr("id");
      let selection = false;  

      if (typeof attrid!=="undefined") {
        if (attrid.includes("react-select")) {
          selection = true;
        }
      }

      if (!selection) {
        if ($(obj).val()==""){
          if (!optionalField.includes($(obj).attr("name"))){
            if ($(obj).attr("placeholder")=="Select a date") {
              $(obj).parent().parent().parent().append("<span class='errCs'>Required!</span>");
            } else {
              $(obj).parent().parent().append("<span class='errCs'>Required!</span>");
            }
            notValid+=1;
          }
        }
      }

      if ($(obj).attr("name")=="phoneNo" || $(obj).attr("name")=="cardPin" || $(obj).attr("name")=="cardNo") {
        if (!isNumeric($(obj).val())) {
          $(obj).parent().parent().append("<span class='errCs'> Please input numbers only!</span>");
        }
      }

    })
    $("textarea").each(function(i,obj){
      if ($(obj).val()==""){
        if (!optionalField.includes($(obj).attr("name"))) {
          $(obj).parent().parent().append("<span class='errCs'>Required!</span>");
          notValid+=1;
        }
      }
    })
    if (notValid==0) {
      setActiveStep(activeStep + 1);
    }
  }
  const handleBack = () => setActiveStep(activeStep - 1);

  const [data, setData] = useState({
    accountKey:"",
    active:false,
    address:"",
    branchCode:"",
    branchName:"",
    cardNo:"",
    cardPin:"",
    dob:"",
    email:"",
    firstName:"",
    gender:"",
    hasLoyaltyCard:"",
    joinedDate:"",
    lastName:"",
    localStore:"",
    notificationsEnabled:"",
    phoneNo:"",
    pointsAccumulated:"",
    promotionsEnabled:"",
    title:"",
    uid:""
  });

  const location = useLocation();
  let params = new URLSearchParams(location.search);
  let cardNo = params.get('cardNo');
  let cardPin = params.get('cardPin');
  const readUser = async () => {
    try{
        const response = await axios
        .get(`${ApiRequests.getRIUserFromCard}/${cardNo}/${cardPin}`)
        .then((response) => {
        setData(response.data)
        //console.log(response)
      });
    }catch(err){
      if(err.response){
        //Not in the 200 response range.
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      }
      else{
        console.log(`Error: ${err.message}`);
      }
    }
  };
  console.log(data);
  useEffect(() => { 
    if (cardNo) { 
      readUser();
    }
  }, []);

  const handleCreate = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button btn-save button-success",
        cancelButton: "buttton btn-quit",
      },
      buttonsStyling: false,
    });

    let notValid = 0;

    let optionalField = ["title","gender","phoneNo"];
    $(".errCs").remove();
    $("input").each(function(i,obj){
      let attrid = $(obj).attr("id");
      let selection = false;  

      if (typeof attrid!=="undefined") {
        if (attrid.includes("react-select")) {
          selection = true;
        }
      }

      if (!selection) {
        if ($(obj).val()==""){
          if (!optionalField.includes($(obj).attr("name"))){
            if ($(obj).attr("placeholder")=="Select a date") {
              $(obj).parent().parent().parent().append("<span class='errCs'>Required!</span>");
            } else {
              $(obj).parent().parent().append("<span class='errCs'>Required!</span>");
            }
            notValid+=1;
          }
        }
      }

      if ($(obj).attr("name")=="phoneNo" || $(obj).attr("name")=="cardPin" || $(obj).attr("name")=="cardNo") {
        if (!isNumeric($(obj).val())) {
          $(obj).parent().parent().append("<span class='errCs'> Please input numbers only!</span>");
          notValid+=1;
        }
      }

    })
    $("textarea").each(function(i,obj){
      if ($(obj).val()==""){
        if (!optionalField.includes($(obj).attr("name"))) {
          $(obj).parent().parent().append("<span class='errCs'>Required!</span>");
          notValid+=1;
        }
      }
    })
    if (notValid==0) {
    

    newSwal
      .fire({
        title: "Create or Quit",
        text: "Would you like to create this user or quit?",
        icon: "question",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Create",
        reverseButtons: true,
        cancelButtonText:"Quit",           
      })
      .then((result) => {
/*
        if (result.isConfirmed) {
          Swal.fire({title:"Saved!", text:"Your progress has been saved.", type:"success", icon:"success"}).then(function(){
            navigate("/users");
            //window.location.href = "/users";
          });
        }
        else if (result.isDenied) {
*/
        if (result.isConfirmed) {

          var timestamp = new Date();
          timestamp = timestamp.toISOString();
          setData({...data,"joinedDate":timestamp});

          let userCreate;

          if (cardNo) { 
            userCreate = ApiRequests.createUserFromInputCard;
          } else {
            userCreate = ApiRequests.createUser;
          }

          axios({
            method: "post",
            url: userCreate,
            data: data,
          }).then(function (response) {
            Swal.fire({title:"Created!", text:"User has been created.", type:"success", icon:"success"}).then(function(){
              navigate("/users");
              //window.location.href = "/users";
            });
          }).catch(function (error) {
            Swal.fire({title:"Failed!", text:error.response.data.message, type:"error", icon:"error"}).then(function(){
            });
          });
        }
        else if (result.dismiss === Swal.DismissReason.cancel) {
          navigate("/users");
          //window.location.href = "/users";
        }
      });

    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox pt={6} pb={8}>
        <SoftBox display="flex" justifyContent="flex-end" mr={12}>
          <ContinueSaveAlert />
        </SoftBox>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <SoftBox mb={1} textAlign="center">
              <SoftBox mb={1}>
                <SoftTypography variant="h1" fontWeight="regular">
                  Create New User
                </SoftTypography>
              </SoftBox>
              <SoftTypography variant="h6" fontWeight="regular" color="secondary">
                This information will let us create a new user to the system.
              </SoftTypography>
            </SoftBox>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Card sx={{ overflow: "visible" }}>
              <SoftBox p={2}>
                <SoftBox>
                  {getStepContent(activeStep)}
                  <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    {activeStep === 0 ? (
                      <SoftBox />
                    ) : (
                      <SoftButton variant="gradient" color="secondary" onClick={handleBack}>
                        back
                      </SoftButton>
                    )}
                    <SoftButton
                      variant="gradient"
                      color="warning"
                      onClick={!isLastStep ? handleNext : handleCreate}
                    >
                      {isLastStep ? "create" : "next"}
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewUsers;
