// import hooks.
import { useEffect, useState } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
import { useLocation } from "react-router-dom";
import $ from "jquery";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

// EditStore page components
import FormField from "layouts/edit-store/components/FormField";

let minAddress = 4;

function StoreInfo({ postData, setPostData }){
/**
 *  read API function 
 */
  const location = useLocation();

  let params = new URLSearchParams(location.search);
  let storeId = params.get('storeId');

/// initialize state using useState
  const [data, setData] = useState([]);
/// axios req & res
  const readStore = async () => {
    try{
        const response = await axios
        .get(`${ApiRequests.readStore+storeId}`)
        //.get(`${ApiRequests.readStore}`)
        .then((response) => {
        setData(response.data);
        localStorage.setItem("detailStore"+storeId, JSON.stringify(response.data));
        $("#title").val(response.data.title);
        $("#address").val(response.data.address);
        $("#description").val(response.data.description);
        $("#phone_number").val(response.data.phoneNo);
        if (response.data.latlong) {
          $("#latlong").val(response.data.latlong.latitude + "," +response.data.latlong.longitude);
        }
        //console.log(response)
      });
    }catch(err){
      if(err.response){
        //Not in the 200 response range.
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      }
      else{
        console.log(`Error: ${err.message}`);
      }
    }
  };
/// tell React that your component needs to do something after render.
  useEffect(() => {  
    readStore();
  }, []);
/**
 * update API function.
 */
  const handleInput = (event) => {
    
    if (event.target.name=="address") {
      if ($("#address").val().length>=minAddress) {

        axios({
          method: "get",
          url: "https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input="+$("#address").val()+"&inputtype=textquery&fields=formatted_address%2Cname%2Crating%2Copening_hours%2Cgeometry&key=AIzaSyDYxnJCe1XM9JPwQO6YtpHvU4LCeUQl3pE",
        }).then(function (response) {
          if (response.data.status=="OK") {
            if (response.data.candidate) {
              let lat = response.data.candidate[0].geometry.location.lat;
              let long = response.data.candidate[0].geometry.location.lng;
              $("#latlong").val(lat+", "+long);
            }
          }
        }).catch(function (error) {
          console.log(error)
        });

      }
    }

    setData({...data, [event.target.name]: event.target.value});
    setPostData({...postData, [event.target.name]: event.target.value});
  };


  return (
    <Card sx={{ overflow: "visible" }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5" fontWeight="regular">Store Information</SoftTypography>
        <SoftBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField type="text" label="title*" id="title" name="title" required/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField type="tel" label="telephone*" id="phone_number" name="phoneNo" required/>
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField type="text" label="address*" id="address" name="address" required/>
            </Grid>
            <Grid item xs={12}>
              <FormField id="latlong" type="text" label="Lat/Long*" placeholder="Lat/Long*" required/>
            </Grid>
            <Grid item xs={12}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="block">
                <SoftTypography component="label" variant="button" fontWeight="regular">
                  Description*
                </SoftTypography>
              </SoftBox>
              <SoftInput id="description" multiline rows={5} name="description" />
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

StoreInfo.propTypes = {
  postData: PropTypes.object,
  setPostData: PropTypes.func,
};

export default StoreInfo;
