// import hooks.
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// sweetalert2 components
import Swal from "sweetalert2";

// Sweet Alerts page components
import Template from "layouts/loyalty-card/alerts/SearchAlert/Template";
import $ from "jquery";

function isNumeric(str) {
  if (typeof str != "string") return false
  return !isNaN(str) &&
         !isNaN(parseFloat(str))
}

function SearchAlert({ cardNo, cardPin }) {
  const navigate = useNavigate();
  const showAlert = async(event) => {
    event.preventDefault();
    //console.log(cardNo," ", cardPin);
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button btn-save button-success",
        cancelButton: "button btn-continue",
      },
      buttonsStyling: false,
    });

    let notValid = 0;

  let optionalField = [];

  $(".errCs").remove();
  $("input").each(function(i,obj){
    let attrid = $(obj).attr("id");
    let selection = false;  

    if (typeof attrid!=="undefined") {
      if (attrid.includes("react-select")) {
        selection = true;
      }
    }

    if (!selection) {
      if ($(obj).val()==""){
        if (!optionalField.includes($(obj).attr("name"))){
          if ($(obj).attr("placeholder")=="Select a date") {
            $(obj).parent().parent().parent().append("<span class='errCs'>Required!</span>");
          } else {
            $(obj).parent().parent().append("<span class='errCs'>Required!</span>");
          }
          notValid+=1;
        }
      }
    }

    if ($(obj).attr("name")=="cardNum" || $(obj).attr("name")=="pinNum") {
      if (!isNumeric($(obj).val())){
        $(obj).parent().parent().append("<span class='errCs'> Please input numbers only!</span>");
        notValid+=1;
      } else {
        if ($(obj).attr("name")=="cardNum") {
          let val = $(obj).val();
          if (val.length!==9) {
            $(obj).parent().parent().append("<span class='errCs'> Only 9 digit allowed!</span>");
            notValid+=1;
          }
        }

        if ($(obj).attr("name")=="pinNum") {
          let val = $(obj).val();
          if (val.length<5) {
            $(obj).parent().parent().append("<span class='errCs'> Please input atleast 5 digit!</span>");
            notValid+=1;
          }
        }
      }

    }

  })

  if (notValid==0) {

    newSwal
      .fire({
        title: "Search",
        text: "Continue search?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Search",
        reverseButtons: true,
        cancelButtonText:"Cancel",     
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios.get(`${ApiRequests.getRIUserFromCard}/${cardNo}/${cardPin}`)
          .then((response) => {
            Swal.fire({title:"Search!", text:"Your loyalty card details is being search.", type:"success", icon:"success"}).then(function(){
              //console.log(response.data);
              const cardNo = response.data.cardNo;
              const cardPin = response.data.cardPin;
              //console.log(cardNo + '' + cardPin);
              navigate(`/new-users?cardNo=${cardNo}&cardPin=${cardPin}`);
              //navigate("/users");
              //window.location.href = "/users";
            });
          }).catch((err) => {
            Swal.fire({title:"Failed!", text:err.response.data.message, type:"error", icon:"error"})
              .then(function() {
              });
          });
        }
      });

  }


  };

  return (
    <Template
      action={showAlert}
    />
  );
}

SearchAlert.propTypes = {
  cardNo: PropTypes.string,
  cardPin: PropTypes.string,
};

export default SearchAlert;
