// Icon
import ShopSvg from "assets/images/shuz_shop-svg.svg";

const branchSignupsData = [
  {
    Branch: [ShopSvg, "Cork"],
    "Signups this month": "40",
    signups: "2500",
  },
  {
    Branch: [ShopSvg, "Kerry"],
    "Signups this month": "15",
    signups: "3900",
  },
  {
    Branch: [ShopSvg, "Dublin"],
    "Signups this month": "60",
    signups: "1300",
  },
  { 
    Branch: [ShopSvg, "Galway"],
    "Signups this month": "20",
    signups: "920",
  },
];

export default branchSignupsData;
