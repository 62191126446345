// import hooks.
import { useEffect, useState } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
import { useLocation } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import PropTypes from "prop-types";

// EditUser page components
import FormField from "layouts/edit-user/components/FormField";

function ContactInfo({data,setData}) {
  
  const handleInput = (event) => {
    setData({...data, [event.target.name]: event.target.value});
  };

  return (
    <Card sx={{ overflow: "visible" }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5" fontWeight="regular">
          Contact Information
        </SoftTypography>
        <SoftBox mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormField type="text" onChange={handleInput} name="address" label="Address*" value={data?.address} required/>
            </Grid>
            <Grid item xs={12}>
              <FormField onChange={handleInput} type="tel" name="phoneNo" label="Phone Number" value={data?.phoneNo} />
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

ContactInfo.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
};

export default ContactInfo;
