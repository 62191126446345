// import hooks.
import { useEffect, useState } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// EditOffer page components
import FeaturedImage from "layouts/edit-offer/components/FeaturedImage";
import OfferInfo from "layouts/edit-offer/components/OfferInfo";
import OfferImage from "layouts/edit-offer/components/OfferImage";
import AdditionalInfo from "layouts/edit-offer/components/AdditionalInfo";
import Notification from "layouts/edit-offer/components/Notification";
import SaveChanges from "layouts/edit-offer/alerts/SaveChanges";
import ContinueSaveAlert from "layouts/edit-offer/alerts/ContinueSaveAlert";

function EditOffer() {
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  let offerId = params.get('offerId');
  const currentDate = new Date().toLocaleString('en-GB', {
    hour12: false,
  }).replace(',','');
  const [postData, setPostData] = useState({
    active:true,
    ageRange:"",
    brand:"",
    createdTime:`${currentDate}`,
    description:"",
    endDate:"",
    featureOffer:true,
    featuredImageURL:"",
    gender:"",
    notificationCategory:"",
    notificationText:"",
    //offer:"",
    offerScope:"",
    offerId:`${offerId}`,
    //offerId:"",
    offerImageURLs:"",
    prevPrice:"",
    startDate:"",
    title:"",
  });

  /// axios req & res
  const readOffer = async () => {
    try{
        const response = await axios
        .get(`${ApiRequests.readOffer+offerId}`)
        .then((response) => {
        setPostData(response.data)
        //console.log(response)
      });
    }catch(err){
      if(err.response){
      //Not in the 200 response range.
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
      }
      else{
      console.log(`Error: ${err.message}`);
      }
    }
  }
/// tell React that your component needs to do something after render.
  useEffect(() => {  
    readOffer();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <SoftTypography variant="h2" fontWeight="regular">
                Make the changes below
              </SoftTypography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <ContinueSaveAlert />
                <SoftBox ml={1}>
                  <SaveChanges postData={postData} />
                </SoftBox>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FeaturedImage postData={postData} setPostData={setPostData} />
              </Grid>
              <Grid item xs={12}>
                <OfferImage postData={postData} setPostData={setPostData} />
              </Grid>
              <Grid item xs={12}>
                <AdditionalInfo postData={postData} setPostData={setPostData} />
              </Grid>
            </Grid>            
          </Grid>
          <Grid item xs={12} lg={8}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <OfferInfo postData={postData} setPostData={setPostData} />
              </Grid>
              <Grid item xs={12}>
                <Notification postData={postData} setPostData={setPostData} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditOffer;
