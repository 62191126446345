/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// reactstrap components
/*
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";
*/
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Soft UI Dashboard PRO React page layout routes
import pageRoutes from "page.routes";

// Images
import TeamChatImg from "assets/images/Shuz4U-our-values.jpeg";

function CardImageLayout() {
  return (
    <>
    <Card sx={{ height: "100%", bgColor:"#fff", borderRadius:"0" }} >
      <SoftBox p={6} className="softbox">
        <SoftTypography variant="body 2" fontWeight="medium" sx={{color:"#000"}}>
          At Shuzgroup, we are focused on putting people first – offering comfort and care to our employees and customers and supporting both with dignity and respect. Human rights is a core value at the heart of how we conduct our business, at every level of our company – from how we treat our suppliers and customers to the rights of our employees.
        </SoftTypography>
      </SoftBox>
    </Card>
{/*
      <div className="testimonials-3">
        <Container>
          <Row>
            <div
              className="carousel slide carousel-team"
              id="carousel-testimonials3"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="info text-left bg-danger">
                    <div className="icon icon-white">
                      <i className="fa fa-quote-right" />
                    </div>
                    <p className="description text-white">
                      At Shuzgroup, we are focused on putting people first &hyphen; offering comfort and care to our employees and customers and supporting both with dignity and respect. Human rights is a core value at the heart of how we conduct our business, at every level of our company &hyphen; from how we treat our suppliers and customers to the rights of our employees.
                    </p>
                  </div>
                  <Card
                    className="card-blog card-background"
                    data-animation={true}
                  >
                    <div
                      className="full-background"
                      style={{
                        backgroundImage:
                          "url(" +
                          require("assets/img/marc-olivier-jodoin.jpg") +
                          ")"
                      }}
                    />
                  </Card>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
*/}
    </>
  );
}

export default CardImageLayout;
