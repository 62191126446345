const pageOptions = 1;
let current = new Date();
const fromDate = "01/01/2000";
const toDate = current.toLocaleDateString('en-GB');
const api_user_pwd = 'Test123456';
//const brandId = '5uj6nqv1abkc0jjq';//testing purpose.
/** ApiRequests */
const ApiRequests = {
/** API get tbl data & data */
    //getAllOffers: `/offer/api/getAllOffers`,
    getAllOffersCheck: `/offer/api/getAllOffers`,
    getAllOffers: `/offer/api/getAllOffers/web`,
    getAllUsers: `/user/api/getAllUsers/web`,
    listAllStores: `/store/api/listAllStores/web`,
    listAllBrands: `/brand/api/listAllBrands/web`,
    listAllNotifications: `/notifications/api/listAllNotifications/web`,
    //getGenderDemographics: `/analytics/api/getGenderDemographics`,
    getAnalyticsData: `/analytics/api/getAnalyticsData`,
    getAllBranches: `/branch/api/getAllBranches/web`,
/** API read */
    //readBrand:`/brand/api/readBrand/${brandId}`,//testing purpose.
    readBrand:`/brand/api/readBrand/`,
    readStore:`/store/api/readStore/`,
    readOffer:`/offer/api/readAnOffer/`,
    readUser: `/user/api/readUser/`,
    readNotification:`/notifications/api/getNotification/`,
    readBranch: `/branch/api/readBranch/`,
/** API create */
    createStore: `/store/api/createStore`,
    createUser: `/user/api/createNewUser?password=${api_user_pwd}`,
    createBrand: `/brand/api/addNewBrand`,
    createOffer: `/offer/api/createOffer`,
    createNotif: `/notifications/api/addNewNotification`,
    createBranch: `/branch/api/createBranch`,
    createUserFromInputCard: `/user/api/createUserFromInputCard?password=${api_user_pwd}`,
/** Loyalty card */
    getRIUserFromCard: `/user/api/getRIUserFromCard`,
    getUserFromCard: `/user/api/getUserFromCard`,
/** API update */
    updateBrand: `/brand/api/updateBrand`,
    updateUser: `/user/api/updateUser`,
    updateStore: `/store/api/updateStore`,
    updateOffer: `/offer/api/updateOffer`,
    updateNotif: `/notifications/api/updateNotification`,
    updateBranch: `/branch/api/updateBranch`,
/** API toggle */
    toggleBranch: `/branch/api/toggleBranchStatus`,
    toggleOffer: `/offer/api/changeStatus`,
    toggleFeatured: `/offer/api/featureOffer`,
    toggleBrand: `/brand/api/changeStatus`,
    toggleStore: `/store/api/changeStatus`,
    toggleUser: `/user/api/changeStatus`,
    toggleNotification: `/notifications/api/changeStatus`,
    
/** API remove */
    removeStore: `/store/api/removeStore`,
    removeBrand: `/brand/api/removeBrand`,
    removeOffer: `/offer/api/removeOffer`,

/** API Auth */
    adminSignIn:`/adminRest/api/admin`,
    adminResetPwd: `/adminRest/api/forgotPassword`,
    adminChangePwd: `/adminRest/api/changePassword`, 
    adminChangePwdWithOtp: `/adminRest/api/verifyPasswordOTP`, 
    adminChangePwd2: `/adminRest/api/changePassword2`,
    adminUpdateDetail: `/adminRest/api/updateAdminDetails`,

    uploadFeatureImage:`/offer/api/uploadFeaturedImage`,
    uploadOfferImage:`/offer/api/uploadOfferImage`,
    uploadBrandImage:`/brand/api/uploadBrandImage`,

    exportAllUser:`/user/api/exportAllUsers/web`
};
export default ApiRequests;