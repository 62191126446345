// prop-types is a library for type checking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function Template({ action }) {
  return(
    <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
      <Tooltip title="Delete Store" placement="top">
        <Icon onClick={action}>delete</Icon>
      </Tooltip>
    </SoftTypography>
  );
}

// Typechecking props for the Template
Template.propTypes = {
  action: PropTypes.func.isRequired,
};

export default Template;
