// import hooks.
import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import SendVerificationCode from "layouts/authentication/reset-password/illustration/alerts/SendVerification";

// Image
import lock from "assets/images/illustrations/lock.png";

function Illustration() {
  const [emailID, setEmail] = useState("");
  const handleInput = (event) => {
    setEmail(event.target.value);
  };
  return (
    <IllustrationLayout
      title="Reset Password"
      description="Please enter the email address you'd like your password reset information sent to."
      /*
      description="You will receive an e-mail in maximum 60 seconds"      
      illustration={{
        image: lock,
      }}
      */
    >
      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          <SoftInput onChange={handleInput} name="email" type="email" placeholder="Email" size="large" icon={{component: "email", direction: "left", }}/>
        </SoftBox>
{/*
        <SoftBox mb={2}>
          <SoftInput placeholder="Verification Code" size="large" icon={{component: "code", direction: "left", }}/>
        </SoftBox>
*/}
        <SoftBox mt={4} mb={1}>
          <SendVerificationCode emailID={emailID} />
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Remember your password?&nbsp;
            <SoftTypography
              component={Link}
              to="/authentication/sign-in"
              variant="button"
              color="warning"
              fontWeight="bold"
              textGradient
            >
              Sign in
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </IllustrationLayout>
  );
}

export default Illustration;
