import { useState } from "react";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftEditor from "components/SoftEditor";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import SoftDatePicker from "components/SoftDatePicker";

// LoyaltyCardInfo page components
///import FormField from "layouts/ecommerce/products/new-product/components/FormField";
import FormField from "layouts/loyalty-card/components/FormField";
import CancelFormAlert from "layouts/loyalty-card/alerts/CancelFormAlert";
import SearchAlert from "layouts/loyalty-card/alerts/SearchAlert";

function LoyaltyCardInfo() {
  const [getCardNum, setCardNum] = useState("");
  const [getPinNum, setPinNum] = useState("");

  return (
    <SoftBox>
      <SoftTypography variant="h3" fontWeight="medium">Loyalty Card Details</SoftTypography>
      <SoftBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField name="cardNum" onChange={(event) => {setCardNum(event.target.value)}} type="text" label="enter 9 digit card number*" required/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField name="pinNum" onChange={(event) => {setPinNum(event.target.value)}} type="tel" label="enter 5 digit pin number*" required/>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox display="flex" justifyContent="flex-end" mt={3}>
        <SoftBox mr={2}>
          <CancelFormAlert />     
        </SoftBox>
        <SearchAlert cardNo={getCardNum} cardPin={getPinNum} />
      </SoftBox>
    </SoftBox>
  );
}

export default LoyaltyCardInfo;
