/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import $ from "jquery";
import { useEffect } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Settings page components
import FormField from "layouts/settings/components/FormField";
import Swal from "sweetalert2";

function ChangePassword() {
  const passwordRequirements = [
    "One special characters",
    "Min 6 characters",
    "One number (2 are recommended)",
    "Change it often",
  ];

  useEffect(() => {  
    $(document).on("click","#updatePwd",function(e){
      e.preventDefault();

      $(".errCs").remove();

      let user_login = JSON.parse(localStorage.getItem("user_login"));

      let oldPassword = $("#old_pwd").val();
      let newPassword = $("#new_pwd").val();
      let confirm_pwd = $("#confirm_pwd").val();
      let invalid = 0;

      if (!oldPassword) {
        $("#old_pwd").parent().parent().append("<span class='errCs'>Required!</span>");
        invalid+=1;
      }

      if (!newPassword) {
        $("#new_pwd").parent().parent().append("<span class='errCs'>Required!</span>");
        invalid+=1;
      }

      if (!confirm_pwd) {
        $("#confirm_pwd").parent().parent().append("<span class='errCs'>Required!</span>");
        invalid+=1;
      }

      if (newPassword !== confirm_pwd) {
        $("#confirm_pwd").parent().parent().append("<span class='errCs'>Password confirmation does not match with new password!</span>");
        invalid+=1;
      }

      let emailId = user_login.email;

      if (invalid==0) {

        axios.get(`${ApiRequests.adminChangePwd2}`, {params:{emailId, newPassword, oldPassword}})
        .then((response) => {
          if (response) {
            Swal.fire({
              title: "Success!",
              text:"Your password has been changed.",
              icon: "success",
              confirmButtonText: "Continue",
            }).then((result) => {
              if(result.isConfirmed) {
                window.location.reload();
              }
            })
          }
        });

      }

    });
  }, []);

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <SoftBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <SoftTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {item}
        </SoftTypography>
      </SoftBox>
    );
  });

  return (
    <Card id="change-password">
      <SoftBox p={3}>
        <SoftTypography variant="h5">Change Password</SoftTypography>
      </SoftBox>
      <SoftBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField id="old_pwd"
              label="current password"
              placeholder="Current Password"
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField id="new_pwd"
              label="new password"
              placeholder="New Password"
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField id="confirm_pwd"
              label="confirm new password"
              placeholder="Confirm Password"
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
        </Grid>
        <br/>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
        >
          <SoftBox ml="auto">
            <SoftButton id="updatePwd" variant="gradient" color="dark" size="small">
              update password
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default ChangePassword;
