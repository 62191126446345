// import hooks.
import { useEffect, useState } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// EditOffer page components
import FormField from "layouts/edit-offer/components/FormField";

function AdditionalInfo({ postData, setPostData }) {
  
  const genderOption = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "others", label: "Others" },
  ];

  const ageOption = [
    { value: "ZeroToTen", label: "0-10" },
    { value: "TenToTwenty", label: "10-20" },
    { value: "TwentyToThirty", label: "20-30" },
    { value: "ThirtyToForty", label: "30-40" },
    { value: "FortyToFifty", label: "40-50" },
    { value: "FiftyToSixty", label: "50-60" },
    { value: "SixtyToSeventy", label: "60-70" },
    { value: "SeventyToEighty", label: "70-80" },
    { value: "EightyToNinety", label: "80-90" },
    { value: "NinetyToHundred", label: "90-100" },
    { value: "HundredPlus", label: "Above 100" }
  ];

  const handleSelectAge = (option) => {
    //console.log(option.value);
    setPostData({...postData, "ageRange": option.value});
  };
  const handleInput = (event) => {
    setPostData({...postData, [event.target.name]: event.target.value});
  };
  const handleSelect = (option) => {
    setPostData({...postData, "gender": option.value});
  };

  return (
    <Card sx={{ overflow: "visible" }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5" fontWeight="regular">
          Additional Information
        </SoftTypography>
        <SoftBox mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
            <SoftBox mb={3}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  Age Range
                </SoftTypography>
              </SoftBox>
              <SoftSelect
                onChange={handleSelectAge}
                options={ageOption}
                value={ageOption.find(({value}) => value === postData?.ageRange)}
              />
            </SoftBox>
          </Grid>
            <Grid item xs={12}>
              <FormField type="text" label="brand" value={postData?.brand} onChange={handleInput} name="brand" />
            </Grid>
            <Grid item xs={12}>
              <SoftBox mb={3}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    textTransform="capitalize"
                  >
                    Gender
                  </SoftTypography>
                </SoftBox>
                <SoftSelect
                  onChange={handleSelect}
                  options={genderOption}
                  value={genderOption.find(({value}) => value === postData?.gender)}
                />
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

AdditionalInfo.propTypes = {
  postData: PropTypes.object,
  setPostData: PropTypes.func,
};

export default AdditionalInfo;
