// import hooks.
import { useEffect, useState, useMemo } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";

// Soft UI Dashboard PRO React example components
import Calendar from "examples/Calendar";

function CalendarEventComponent(){
/// initialize state using useState
    const [data, setData] = useState([]);
/// axios req & res
    const getAllOffers = async () => {
        try{
            const response = await axios
            .get(`${ApiRequests.getAllOffersCheck}`)
            .then((response) => {
                //response.data
                setData(response.data)
                //console.log(response.data)
            });
            }catch(err){
            if(err.response){
                //Not in the 200 response range.
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
            }
            else{
                console.log(`Error: ${err.message}`);
            }
        }
    };    
/// tell React that your component needs to do something after render.
    useEffect(() => {  
        getAllOffers();
    }, []);
    //console.log(data);
/*** if its ***active*** or featured offer, show/hide offers ***/
    const filtered_data = data.map((row)=>{
        if(row?.active === true){
            return row;
        }
    //by doing this, you can ensure what's returned is not undefined
    }).filter(notUndefined => notUndefined !== undefined);
    //console.log(filtered_data);
/*
    const res_data = data.map((row)=>({
        title: row?.title,
        //start: row?.startDate.split('/').reverse().join('-'),
        startDate: row.startDate?row.startDate.substring(0, 10).split('/').reverse().join('-'):"",
        //end: row?.endDate.split('/').reverse().join('-'),
        endDate: row.endDate?row.endDate.substring(0, 10).split('/').reverse().join('-'):"",
        active:row?.active,
        offerId: row?.offerId,
    }));
*/
/*
    data.forEach((data) => {
        if(data?.active === true){
            console.log(data?.active);
        }
    });
    for(data of data_val){
        if (data_val?.active === "true"){
            return data_val;
        }
   }
   console.log(data_val);
*/
/*
    const data_rows = data.map((row) => ({
        title: row?.title,
        //start: row?.startDate.split('/').reverse().join('-'),
        start: row.startDate?row.startDate.substring(0, 10).split('/').reverse().join('-'):"",
        //end: row?.endDate.split('/').reverse().join('-'),
        end: row.endDate?row.endDate.substring(0, 10).split('/').reverse().join('-'):"",
        className:"info",
        offerId: row?.offerId,
    }));
*/
    const data_rows = filtered_data.map((row) => ({
        title: row?.title,
        start: row.startDate?row.startDate.substring(0, 10).split('/').reverse().join('-'):"",
        end: row.endDate?row.endDate.substring(0, 10).split('/').reverse().join('-'):"",
        className:"info",
        offerId: row?.offerId,
    }));
    //console.log(data_rows);
    return(
        <>
{/***/}         
        {useMemo(
            () => (
                <Calendar
                    header={{ title: "calendar"}}
                    initialView="dayGridMonth"                    
                    events={data_rows}
                    selectable
                    editable
                />
            ),
            [data_rows]
        )}

        </>
    );    
}
export default CalendarEventComponent;