/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Web app by: https://www.speire.ie
* Copyright 2023

 =========================================================
*
*/

import { useState } from "react";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// PrivacyCookiePolicy page components
/*import here if any.*/

function PrivacyCookiePolicy() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox pt={6} pb={8}>
        <Grid container>
          <Grid item xs={12} lg={8}>
            <SoftBox mb={4}>
              <SoftBox mb={1}>
                <SoftTypography variant="h1" fontWeight="regular">
                  Privacy Policy & Cookie Policy.
                </SoftTypography>
              </SoftBox>
              <SoftTypography variant="h6" fontWeight="regular" color="secondary">
                Contact&nbsp;
                <Link to='javascript:void(0)'
                  onClick={() => window.location = 'mailto:sketchers@email.com'}>
                  sketchers@email.com&nbsp;
                </Link>
                for more details.
              </SoftTypography>
            </SoftBox>          
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PrivacyCookiePolicy;
