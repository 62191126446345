/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function Footer() {
  return (
    <SoftBox component="footer" bgColor="#000" pb={5}>
      <Grid container justifyContent="center" spacing={0} alignItems="center">
        <Grid item xs={12}>
          <SoftBox textAlign="center">
            <SoftTypography variant="body 2" fontWeight="regular" sx={{color:"#fff"}}>
              &copy; {new Date().getFullYear()} Shuz4U Distribution Ltd.
            </SoftTypography>
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default Footer;
