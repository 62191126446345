// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// NewOffer page components
///import FormField from "layouts/ecommerce/products/new-product/components/FormField";
import FormField from "layouts/new-offers/components/FormField";

function AdditionalInfo({postData, setPostData}) {

  const handleInput = (event) => {
    setPostData({...postData, [event.target.name]: event.target.value});
  };
  const handleSelectAge = (option) => {
    //console.log(option.value);
    setPostData({...postData, "ageRange": option.value});
  };
  const handleSelect = (option) => {
    //console.log(option.value);
    setPostData({...postData, "gender": option.value});
  };

  const genderOption = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "others", label: "Others" },
  ];

  const ageOption = [
    { value: "ZeroToTen", label: "0-10" },
    { value: "TenToTwenty", label: "10-20" },
    { value: "TwentyToThirty", label: "20-30" },
    { value: "ThirtyToForty", label: "30-40" },
    { value: "FortyToFifty", label: "40-50" },
    { value: "FiftyToSixty", label: "50-60" },
    { value: "SixtyToSeventy", label: "60-70" },
    { value: "SeventyToEighty", label: "70-80" },
    { value: "EightyToNinety", label: "80-90" },
    { value: "NinetyToHundred", label: "90-100" },
    { value: "HundredPlus", label: "Above 100" }
  ];

  return (
    <SoftBox>
      <SoftTypography variant="h3" fontWeight="medium">
        Additional Information
      </SoftTypography>
      <SoftBox mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SoftBox mb={3}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  Age Range
                </SoftTypography>
              </SoftBox>
              <SoftSelect
                value={ageOption.find(({value}) => value === postData?.age)}
                onChange={handleSelectAge}
                options={ageOption}
                placeholder={postData?.age}
              />
            </SoftBox>
          </Grid>
          <Grid item xs={12}>
            <FormField value={postData?.brand} onChange={handleInput} name="brand" type="text" label="brand" placeholder="" />
          </Grid>
          <Grid item xs={12}>
            <SoftBox mb={3}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  textTransform="capitalize"
                >
                  Gender
                </SoftTypography>
              </SoftBox>
              <SoftSelect
                value={genderOption.find(({value}) => value === postData?.gender)}
                onChange={handleSelect}
                options={genderOption}
                placeholder={postData?.gender}
              />
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

AdditionalInfo.propTypes = {
  postData: PropTypes.object,
  setPostData: PropTypes.func,
};

export default AdditionalInfo;
