// import hooks.
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
import $ from "jquery";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// sweetalert2 components
import Swal from "sweetalert2";

// Sweet Alerts page components
import Template from "layouts/new-brand/alerts/CreateSaveAlert/Template";

function isNumeric(str) {
  if (typeof str != "string") return false
  return !isNaN(str) &&
         !isNaN(parseFloat(str))
}

function CreateSaveAlert({ postData }) {
  const navigate = useNavigate();
  const showAlert = (event) => {
    event.preventDefault();
    //console.log(postData);

    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button btn-save button-success",
        cancelButton: "buttton btn-quit",
      },
      buttonsStyling: false,
    });

    let notValid = 0;
    let optionalField = ["brandImageUrl"];
    $(".errCs").remove();
    $("input").each(function(i,obj){
      let attrid = $(obj).attr("id");
      let selection = false;  

      if (typeof attrid!=="undefined") {
        if (attrid.includes("react-select")) {
          selection = true;
        }
      }

      if (!selection) {
        if ($(obj).val()==""){
          if (!optionalField.includes($(obj).attr("name"))){
            if ($(obj).attr("placeholder")=="Select a date") {
              $(obj).parent().parent().parent().append("<span class='errCs'>Required!</span>");
            } else {
              $(obj).parent().parent().append("<span class='errCs'>Required!</span>");
            }
            notValid+=1;
          }
        }
      }

      if ($(obj).attr("id")=="phone_no") {
        if (!isNumeric($(obj).val())){
          $(obj).parent().parent().append("<span class='errCs'> Please input numbers only!</span>");
          notValid+=1;
        }
      }
    })
    $("textarea").each(function(i,obj){
      if ($(obj).val()==""){
        if (!optionalField.includes($(obj).attr("name"))) {
          $(obj).parent().parent().append("<span class='errCs'>Required!</span>");
          notValid+=1;
        }
      }
    })
    if (notValid==0) {
    newSwal
      .fire({
        title: "Create or Quit",
        text: "Would you like to create this brand or quit?",
        icon: "question",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Create",
        reverseButtons: true,
        cancelButtonText:"Quit",           
      })
      .then((result) => {
/*
        if (result.isConfirmed) {
          Swal.fire({title:"Saved!", text:"Your progress has been saved.", type:"success", icon:"success"}).then(function(){
            navigate("/our-brands");
            //window.location.href = "/our-brands";
          });
        }
        else if (result.isDenied) {
*/
        if (result.isConfirmed) {
          /*
          let optionalField = ["brandImageUrl"];
          $(".errCs").remove();
          $("input").each(function(i,obj){
            if ($(obj).attr("id")!=="react-select-2-input" || $(obj).attr("id")!=="react-select-3-input") {
              if ($(obj).val()==""){
                if (!optionalField.includes($(obj).attr("name")) && $(obj).attr("id")!=="react-select-2-input" && $(obj).attr("id")!=="react-select-3-input"){
                    $(obj).parent().parent().append("<span class='errCs'>Required!</span>");
                }
              }
            }
          })
          $("textarea").each(function(i,obj){
            if ($(obj).val()==""){
              if (!optionalField.includes($(obj).attr("name"))) {
                $(obj).parent().parent().append("<span class='errCs'>Required!</span>");
              }
            }
          })
          */
          ///axios function.          
          axios({
            method:"post",
            url:`${ApiRequests.createBrand}`,
            data:postData,
            //headers: { "Content-Type": "multipart/form-data", "Content-Type": "application/x-www-form-urlencoded",  "accept": "application/json" },
          }).then((response) => {
            Swal.fire({title:"Created!", text:"Brand has been created.", type:"success", icon:"success"})
              .then(function() {
                navigate("/our-brands");
                //window.location.href = "/our-brands";
              });
          }).catch((err) => {
            Swal.fire({title:"Failed!", text:err.response.data.message, type:"error", icon:"error"})
              .then(function() {
              });
          });
        }
/*        
          axios.post(`${ApiRequests.createBrand}`, postData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'accept': 'application/json',
            }
          })
          //axios.post(`${ApiRequests.createBrand}`, postData)
            .then((response) => {
              Swal.fire({title:"Created!", text:"Brand has been created.", type:"success", icon:"success"})
                .then(function() {
                  navigate("/our-brands");
                  //window.location.href = "/our-brands";
                });
            }).catch((err) => {
              Swal.fire({title:"Failed!", text:err.response.data.message, type:"error", icon:"error"})
                .then(function() {
                });
            });
          
          }
*/
        else if (result.dismiss === Swal.DismissReason.cancel) {
          navigate("/our-brands");
          //window.location.href = "/our-brands";
        }        
      });
    }
  };

  return (
    <Template
      action={showAlert}
    />
  );
}

CreateSaveAlert.propTypes = {
  postData: PropTypes.object,
};

export default CreateSaveAlert;
