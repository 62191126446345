// react-router-dom components
import { useNavigate } from "react-router-dom";

// sweetalert2 components
import Swal from "sweetalert2";

// Sweet Alerts page components
import Template from "layouts/users/alerts/ExistLoyaltyCard/Template";

function ExistLoyaltyCard() {
  const navigate = useNavigate();
  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });
    newSwal
      .fire({
        title: "Does this user have an existing Skechers loyalty card?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        reverseButtons: true,
        cancelButtonText:"No",     
      })
      .then((result) => {
        if (result.isConfirmed) {
          navigate("/loyalty-card");
          //window.location.href = "/loyalty-card";
        }
        else if(result.dismiss === Swal.DismissReason.cancel){
          navigate("/new-users");          
          //window.location.href = "/new-users";
        }
      });
  };

  return (
    <Template
      action={showAlert}
    />
  );
}

export default ExistLoyaltyCard;
