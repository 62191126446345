import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

// EditBrand page components
import FormField from "layouts/edit-brand/components/FormField";

function BrandInfo({ postData, setPostData }) {

  const handleInput = (event) => {
    setPostData({...postData, [event.target.name]: event.target.value});
  };

  return (
    <Card sx={{ overflow: "visible" }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5" fontWeight="regular">Brand Information</SoftTypography>
        <SoftBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField type="text" label="title*" value={postData?.title} onChange={handleInput} name="title" required />
            </Grid>
            <Grid item xs={12}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="block">
                <SoftTypography component="label" variant="button" fontWeight="regular">
                  Description*&nbsp;&nbsp;
                </SoftTypography>
              </SoftBox>
              {/*<SoftEditor value={editorValue} onChange={setEditorValue} data-gramm={data.description}/>*/}
                <SoftInput value={postData?.description} multiline rows={5} onChange={handleInput} name="description" />
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

BrandInfo.propTypes = {
  postData: PropTypes.object,
  setPostData: PropTypes.func,
};

export default BrandInfo;
