/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Web app by: https://www.speire.ie
* Copyright 2023

 =========================================================
*
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import PageCustomLayout from "examples/LayoutContainers/PageCustomLayout";

// Layout components
import Footer from "layouts/privacy-policy/components/Footer";

// Images
import ShuzGroupNavLogo from "assets/images/shuzgroup-logo-white-svg.svg"

function PrivacyPolicy() {
  return (
    <PageCustomLayout>
      <SoftBox bgColor="#000" mb={0} id="privacy-policy-pg">
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" sx={{p:'0 6% 2%'}} className="nav-section">
          <Link to="/about-us">
            <SoftBox lineHeight={1}>
              <SoftBox
                component="img"
                src={ShuzGroupNavLogo}
                title="ShuzGroup Logo"
                alt="ShuzGroup Logo"
                my={3}
              />
            </SoftBox>
          </Link>
          <Stack spacing={1} direction="row" alignItems="center">
            <Link to="/about-us">
              <SoftButton mr={2} variant="text" color="white" size="medium">
                About Shuzgroup
              </SoftButton>
            </Link>
          </Stack>
        </SoftBox>
        <SoftBox className="content-section">
          <Grid container justifyContent="center" sx={{pb:'4%'}}>
            <Grid item xs={12} lg={9} pb={6}>
              <SoftBox>
                <SoftTypography mb={2} variant="h1" fontWeight="regular" color="white">
                  Privacy Policy
                </SoftTypography>
                <SoftTypography variant="body 2" fontWeight="regular" color="white">
                  At Shuz 4 U, we are committed to protecting and respecting your data protection and privacy rights. Please take a moment to read this Privacy Policy to find out more about why and how we process your personal information.<br></br>
                  This Privacy Policy applies to your use of the Shuz 4 U mobile text message service (“the Shuz 4 U Text Service”), the Shuz 4 U email service (“the Shuz 4 U Email Service”), and the Shuz 4 U Website (“the Shuz 4 U Site”), (together the “Shuz 4 U Platform”). The Privacy Policy governs our data collection, processing, and usage practices. By using the Shuz 4 U Platform, and agreeing to the terms of this Privacy Policy, you consent to the data practices described in this Privacy Policy. If you do not agree with the data practices described in this Privacy Policy, you should not use the Shuz 4 U Platform. In the event you wish to withdraw your consent to the data practices described in this Privacy Policy, please contact us using the contact details in the “Contact Information” section below.
                </SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={9} pb={4}>
              <SoftBox>
                <SoftTypography mb={2} variant="h3" fontWeight="regular" color="white">
                  WHO PROCESSES YOUR DATA
                </SoftTypography>
                <SoftTypography variant="body 2" fontWeight="regular" color="white">
                  For the purposes of applicable data protection legislation, Shuz 4 U Limited (“Shuz 4 U”), with offices at Unit 15 Euro Innovation Park, Little Island, Cork, Ireland is the data controller of the information that identifies you (“Personal Data”).<br></br>
                  We are not responsible for the content or privacy practices of other websites. Any external links to other websites are clearly identifiable as such.
                </SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={9} pb={4}>
              <SoftBox>
                <SoftTypography mb={2} variant="h3" fontWeight="regular" color="white">
                  PERSONAL DATA WE PROCESS
                </SoftTypography>
                <SoftTypography variant="body 2" fontWeight="regular" color="white">
                  As a data controller, we will keep the Personal Data you provide about yourself confidential. By using the Shuz 4 U Platform, we collect information about you. This includes:<br></br>
                </SoftTypography>
                <List sx={{listStyleType: 'disc', pl:'3%', color:'#fff'}}>
                  <ListItem sx={{display: 'list-item'}}>your name</ListItem>
                  <ListItem sx={{display: 'list-item'}}>your title</ListItem>
                  <ListItem sx={{display: 'list-item'}}>your email address</ListItem>
                  <ListItem sx={{display: 'list-item'}}>your phone number</ListItem>
                  <ListItem sx={{display: 'list-item'}}>your location</ListItem>
                  <ListItem sx={{display: 'list-item'}}>payment information</ListItem>
                </List>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={9} pb={4}>
              <SoftBox>
                <SoftTypography mb={2} variant="h3" fontWeight="regular" color="white">
                  WHY WE PROCESS YOUR PERSONAL DATA
                </SoftTypography>
                <SoftTypography variant="body 2" fontWeight="regular" color="white">
                  We may use your Personal Data to:
                </SoftTypography>
                <List sx={{listStyleType: 'disc', pl:'3%', color:'#fff'}}>
                  <ListItem sx={{display: 'list-item'}}>create a membership account for you within the Shuz 4 U Platform;</ListItem>
                  <ListItem sx={{display: 'list-item'}}>respond to your queries;</ListItem>
                  <ListItem sx={{display: 'list-item'}}>dealing with your requests;</ListItem>
                  <ListItem sx={{display: 'list-item'}}>processing of sales purchases</ListItem>
                  <ListItem sx={{display: 'list-item'}}>account administration</ListItem>
                  <ListItem sx={{display: 'list-item'}}>marketing; for the purposes of statistical and other analytics and research; and</ListItem>
                  <ListItem sx={{display: 'list-item'}}>email you.</ListItem>
                </List>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={9} pb={4}>
              <SoftBox>
                <SoftTypography mb={2} variant="h5" fontWeight="regular" color="white">
                  For Marketing Purposes
                </SoftTypography>
                <SoftTypography variant="body 2" fontWeight="regular" color="white">
                  We may use your Personal Data for the purposes of marketing but only in circumstances where appropriate and specific consent has been provided. Consent is provided by you accepting the use by Shuz 4 U of your Personal Data for marketing purposes on the Shuz 4 U Text Service and/or via the Shuz 4 U Site.<br></br>
                  By agreeing to Shuz 4 U using your Personal Data for marketing purposes, Shuz 4 U may use your Personal Data and details to provide you with information about other, services and special offers either from:
                </SoftTypography>
                <List sx={{listStyleType: 'disc', pl:'3%', color:'#fff'}}>
                  <ListItem sx={{display: 'list-item'}}>Shuz 4 U;</ListItem>
                  <ListItem sx={{display: 'list-item'}}>Life and Sole</ListItem>
                  <ListItem sx={{display: 'list-item'}}>Skechers Inc.</ListItem>
                </List>
                <SoftTypography variant="body 2" fontWeight="regular" color="white">
                  We use your information in connection with:
                </SoftTypography>
                <List sx={{listStyleType: 'disc', pl:'3%', color:'#fff'}}>
                  <ListItem sx={{display: 'list-item'}}>promotions,</ListItem>
                  <ListItem sx={{display: 'list-item'}}>product offers,</ListItem>
                  <ListItem sx={{display: 'list-item'}}>events,</ListItem>
                  <ListItem sx={{display: 'list-item'}}>analysing the use of the Shuz 4 U Site,</ListItem>
                  <ListItem sx={{display: 'list-item'}}>optimising our offerings to you.</ListItem>
                </List>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={9} pb={4}>
              <SoftBox>
                <SoftTypography mb={2} variant="h5" fontWeight="regular" color="white">
                  Information about payments
                </SoftTypography>
                <SoftTypography variant="body 2" fontWeight="regular" color="white">
                  If you use the Shuz 4 U Platform for purchases or financial transactions, we collect information about the purchase or transaction. This includes your:
                </SoftTypography>
                <List sx={{listStyleType: 'disc', pl:'3%', color:'#fff'}}>
                  <ListItem sx={{display: 'list-item'}}>payment information, such as your name,</ListItem>
                  <ListItem sx={{display: 'list-item'}}>credit or debit card number, and</ListItem>
                  <ListItem sx={{display: 'list-item'}}>other card information,</ListItem>
                  <ListItem sx={{display: 'list-item'}}>account and authentication information, and</ListItem>
                  <ListItem sx={{display: 'list-item'}}>billing information, as well as</ListItem>
                  <ListItem sx={{display: 'list-item'}}>your transaction history.</ListItem>
                </List>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={9} pb={4}>
              <SoftBox>
                <SoftTypography mb={2} variant="h3" fontWeight="regular" color="white">
                  SHARING YOUR PERSONAL DATA
                </SoftTypography>
                <SoftTypography variant="body 2" fontWeight="regular" color="white">
                  We will never sell your Personal Data to third parties. We may share your Personal Data both inside and outside the European Economic Area in the following ways:
                </SoftTypography>
                <List sx={{listStyleType: 'disc', pl:'3%', color:'#fff'}}>
                  <ListItem sx={{display: 'list-item'}}>with Skechers Inc;</ListItem>
                  <ListItem sx={{display: 'list-item'}}>
                    with government agencies or authorities, if information is needed to comply with the law, enforce an agreement we have with you, or to protect our rights, property, or safety, or the rights, property, or safety of our employees or others;
                  </ListItem>
                  <ListItem sx={{display: 'list-item'}}>to address disputes, claims, or to persons holding a legal or beneficial interest; and/or</ListItem>
                  <ListItem sx={{display: 'list-item'}}>in accordance with your express and informed consent.</ListItem>
                </List>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={9} pb={4}>
              <SoftBox pb={4}>
                <SoftTypography mb={2} variant="h5" fontWeight="regular" color="white">
                  Apps, websites, and third-party integrations on or using our services.
                </SoftTypography>
                <SoftTypography variant="body 2" fontWeight="regular" color="white">
                  When you use third party apps, websites, or other services that use and/or are integrated with the Shuz 4 U Platform, you agree that such third parties and their employees and contractors may receive information about you. Information collected by these third parties may be subject to the third parties&apos; own terms and policies.
                </SoftTypography>
              </SoftBox>
              <SoftBox pb={4}>
                <SoftTypography mb={2} variant="h5" fontWeight="regular" color="white">
                  New owner
                </SoftTypography>
                <SoftTypography variant="body 2" fontWeight="regular" color="white">
                  If ownership or control of the Shuz 4 U Platform changes, you agree that we may transfer your information to the new owner.
                </SoftTypography>
              </SoftBox>
              <SoftBox>
                <SoftTypography mb={2} variant="h5" fontWeight="regular" color="white">
                  Social media
                </SoftTypography>
                <SoftTypography variant="body 2" fontWeight="regular" color="white">
                  The Shuz 4 U Platform interfaces with social media websites such as Facebook and Twitter, and may use social media plugins (e.g., the Facebook “Like” button, “Share to Twitter” button) to facilitate social media functions. If you are a member of a social media platform or website, and log in to such social media or platform, the interfaces may allow the social media platform or website to connect your Shuz 4 U Platform visit to your Personal Data. The social media plugins also may allow the social media website to share information about your activities on the Shuz 4 U Platform with other users of their social media platform. For example, Facebook social media plugins allow Facebook to show your Likes and comments on the Shuz 4 U Platform to your Facebook friends. We have no control over the information that social media websites or plugins collect, store, or use. Before you choose to “Like” or share information from the Shuz 4 U Platform through any social media platform or website, please be certain that you review the privacy policy of that social media platform or website.
                </SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={9} pb={4}>
              <SoftBox>
                <SoftTypography mb={2} variant="h3" fontWeight="regular" color="white">
                  YOUR RIGHTS
                </SoftTypography>
                <SoftTypography variant="body 2" fontWeight="regular" color="white">
                  If you contact us to edit or delete your personal data we will ask you questions in order to verify your identity.<br></br>
                  Under the applicable data protection legislation, you have the right to request that we:
                </SoftTypography>
                <List sx={{listStyleType: 'disc', pl:'3%', color:'#fff'}}>
                  <ListItem sx={{display: 'list-item'}}>
                    provide you with information as to whether we process your data and details relating to our processing, and with a copy of your data;
                  </ListItem>
                  <ListItem sx={{display: 'list-item'}}>
                    rectify any inaccurate data we might have about your without undue delay;
                  </ListItem>
                  <ListItem sx={{display: 'list-item'}}>complete any incomplete information about you;</ListItem>
                  <ListItem sx={{display: 'list-item'}}>under certain circumstances, erase your personal data without undue delay,</ListItem>
                  <ListItem sx={{display: 'list-item'}}>under certain circumstances, be restricted from processing your data; and</ListItem>
                  <ListItem sx={{display: 'list-item'}}>under certain circumstances, furnish you with the personal data which you provided us with in a structured, commonly used and machine readable format.</ListItem>
                </List>
                <SoftTypography variant="body 2" fontWeight="regular" color="white">
                  Where we process your Personal Data solely on the basis of your consent, you are entitled to withdraw your consent at any time. This will not affect the lawfulness of our processing before the withdrawal.
                  <br></br><br></br>
                  You also have the right to lodge a complaint with the Data Protection Commissioner at any time. The exercise of your rights might be subject to certain conditions and we might require further information from you before we can respond to your request. You may exercise your rights by contacting our Data Privacy Officer at the address or e-mail address provided in the “Customer Information” section below.
                </SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={9} pb={4}>
              <SoftBox>
                <SoftTypography mb={2} variant="h3" fontWeight="regular" color="white">
                  DATA RETENTION
                </SoftTypography>
                <SoftTypography variant="body 2" fontWeight="regular" color="white">
                  Shuz 4 U will keep your personal data for as long as you continue to interact with us (for example, if you read or click on an email we send you or make a purchase from our stores).
                  <br></br><br></br>
                  If we do not receive a response to our attempt to contact you (or if you tell us, at any time, that you no longer want to be a member of our Loyalty Programme) then we will securely delete your personal data from our records. We generally hold your data for as long as we are obliged to do so under applicable law.
                </SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={9} pb={4}>
              <SoftBox>
                <SoftTypography mb={2} variant="h3" fontWeight="regular" color="white">
                  SECURITY
                </SoftTypography>
                <SoftTypography variant="body 2" fontWeight="regular" color="white">
                  We take our data security responsibilities seriously, employing appropriate physical and technical measures, including staff training and awareness. We review our security measures and procedures regularly to help protect against unauthorised access to, or alteration, disclosure, or destruction of, data. We commit to taking appropriate disciplinary measures to enforce our employees’ privacy responsibilities. We have numerous internal procedures as well as computer interfaced protective barriers to keep your Personal Data as safe and secure as we possibly can. Shuz 4 U currently uses AES128 BIT and 2048-bit RSA SHA-2 SSl certificates on the Shuz 4 U Platform.
                </SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={9} pb={4}>
              <SoftBox>
                <SoftTypography mb={2} variant="h3" fontWeight="regular" color="white">
                  COOKIES
                </SoftTypography>
                <SoftTypography variant="body 2" fontWeight="regular" color="white">
                  We respect the privacy of all users of the Shuz 4 U Platform and would like to tell you more about why and how we use cookies. Cookies are small, often encrypted text files sent by web servers to web browsers and stored there to provide useful information. When the user subsequently visits the Shuz 4 U Site, the browser sends cookies back to the website allowing the user to be recognised. This eliminates the redundant re-entering of data and the website can be used more efficiently.
                  Session Cookies are temporary cookies that are not stored on your computer or mobile device. Persistent cookies are those placed on your computer or mobile device for a pre-determined length of time when you visit a site. We also store cookies retrieved from external sites you have used to navigate to the Shuz 4 U Site, including Google Analytics. The information generated by this type of cookie about your use of the Shuz 4 U Site (including your IP address) is used by search engines to analyse website activity.
                  <br></br><br></br>
                  More information about cookies can be found at: <Link sx={{color:'#fff'}} to='www.allaboutcookies.org' target="_blank">www.allaboutcookies.org</Link>. This site contains information on how to configure your browser to block all cookies as well as general information about cookies.
                </SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={9} pb={4}>
              <SoftBox>
                <SoftTypography mb={2} variant="h3" fontWeight="regular" color="white">
                  DEVICE INFORMATION
                </SoftTypography>
                <SoftTypography variant="body 2" fontWeight="regular" color="white">
                  We collect information from or about the computers, phones, or other devices where you install or access the Shuz 4 U Platform. We may associate the information we collect from your different devices, which helps us provide consistent offerings across your devices. We collect the following information:
                </SoftTypography>
                <List sx={{listStyleType: 'disc', pl:'3%', color:'#fff'}}>
                  <ListItem sx={{display: 'list-item'}}>
                    Attributes such as the operating system, hardware version, device settings, file and software names and types, battery and signal strength, and device identifiers.
                  </ListItem>
                  <ListItem sx={{display: 'list-item'}}>
                    Connection information such as the name of your mobile operator or ISP, browser type, language and time zone, mobile phone number, and IP address.
                  </ListItem>
                </List>
                <SoftTypography variant="body 2" fontWeight="regular" color="white">
                  <br></br>
                  We may use device information to:
                </SoftTypography>
                <List sx={{listStyleType: 'disc', pl:'3%', color:'#fff'}}>
                  <ListItem sx={{display: 'list-item'}}>administer the Shuz 4 U Platform,</ListItem>
                  <ListItem sx={{display: 'list-item'}}>analyse trends,</ListItem>
                  <ListItem sx={{display: 'list-item'}}>gather information about visitor movements and behaviour, and</ListItem>
                  <ListItem sx={{display: 'list-item'}}>improve the performance and effectiveness of the Shuz 4 U Platform.</ListItem>
                </List>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={9} pb={4}>
              <SoftBox pb={4}>
                <SoftTypography mb={2} variant="h3" fontWeight="regular" color="white">
                  CHANGES TO THIS POLICY
                </SoftTypography>
                <SoftTypography variant="body 2" fontWeight="regular" color="white">
                  As Shuz 4 U, its products, and its services change from time to time, this Privacy Policy is expected to change as well. We reserve the right to amend the Privacy Policy at any time, for any reason, and may do so by posting a new version online and in store. Your continued use of the Shuz 4 U Platform and/or continued provision of Personal Data to us will be subject to the terms of the then-current Privacy Policy.
                </SoftTypography>
              </SoftBox>
              <SoftBox>
                <SoftTypography mb={2} variant="h3" fontWeight="regular" color="white">
                  CONTACT INFORMATION
                </SoftTypography>
                <SoftTypography variant="body 2" fontWeight="regular" color="white">
                  If you have any queries, please contact us by email on <SoftBox color="#fff" component="a" href="mailto:customerservice@skechersireland.com">customerservice@skechersireland.com</SoftBox> or by post to the Data Privacy Officer, Unit 15 Euro Innovation Park, Little Island, Cork, Ireland. When signing up to the Life and Sole newsletter you will be asked to tick a box. By ticking the box on the acceptance of this Privacy Policy, you are giving us permission to process these details for the above purposes. You should only tick the box on acceptance.
                </SoftTypography>
              </SoftBox>
            </Grid>
          </Grid>{/* PARENT GRID */}
        </SoftBox>{/* CONTENT-SECTION */}
      </SoftBox>
      <Footer />
    </PageCustomLayout>
  );
}

export default PrivacyPolicy;
