// import hooks.
import { useEffect, useState } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Switch from '@mui/material/Switch';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function StatusCell({ obj, checked }) {
  const storeId = obj.storeId;
  let activeVal = obj.active;
  const [statusChecked, setStatus] = useState(checked);
  const switchHandler = (event)=>{
    event.preventDefault();
    setStatus(event.target.checked);
    axios.post(`${ApiRequests.toggleStore}`, {}, {params:{newStatus:event.target.checked, storeId:storeId }})
    .then((response) => {
      //console.log(response);
      console.log(response.status);
      console.log(response.request.responseURL);
// it re-renders the component
      //setStatus();
    }).catch((err) => {
        if(err.response){
          //Not in the 200 response range.
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        }
        else{
          console.log(`Error: ${err.message}`);
        }      
      });
  };
  return (
    <SoftBox>
      <Switch value={activeVal} onChange={switchHandler} checked={statusChecked}/>
    </SoftBox>
  );
}

/**/
// Setting default value for the props of StatusCell
StatusCell.defaultProps = {
  checked: false,
};

// Typechecking props for the StatusCell
StatusCell.propTypes = {
  //id: PropTypes.string.isRequired,
  obj: PropTypes.object,
  checked: PropTypes.bool,
};

export default StatusCell;
