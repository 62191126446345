/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Web app by: https://www.speire.ie
* Copyright 2023

 =========================================================
*
*/

/** 
  All of the routes for the page layout of Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the DefaultNavbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `name` key is used for the name of the route on the DefaultNavbar.
  3. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  4. The `icon` key is used for the icon of the route on the DefaultNavbar, you have to add a node.
  5. The `collapse` key is used for making a collapsible item on the DefaultNavbar that contains other routes inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  6. The `route` key is used to store the route location which is used for the react router.
  7. The `href` key is used to store the external links location.
*/

// Soft UI Dashboard PRO React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import Document from "examples/Icons/Document";
import CustomerSupport from "examples/Icons/CustomerSupport";
import Cube from "examples/Icons/Cube";
import SpaceShip from "examples/Icons/SpaceShip";

const pageRoutes = [
  {
    name: "Dashboards",
    key: "dashboards",
    icon: <Shop size="12px" color="white" />,
    collapse: [
      {
        name: "Default",
        key: "default",
        route: "/dashboards/default",
      },
    ],
  },
  {
    name: "Illustration",
    key: "illustration",
    route: "/authentication/sign-in/illustration",
  },
  {
    name: "Illustration",
    key: "illustration",
    route: "/authentication/sign-up/illustration",
  },
  {
    name: "Illustration",
    key: "illustration",
    route: "/authentication/reset-password/illustration",
  },
  {
    name: "Illustration",
    key: "illustration",
    route: "/authentication/lock/illustration",
  },
  {
    name: "Illustration",
    key: "illustration",
    route: "/authentication/verification/illustration",
  },
  {
    name: "Error 404",
    key: "error-404",
    route: "/authentication/error/404",
  },
  {
    name: "Error 500",
    key: "error-500",
    route: "/authentication/error/500",
  },
];

export default pageRoutes;
