import { useState } from "react";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftDatePicker from "components/SoftDatePicker";
import SoftButton from "components/SoftButton";

// NotificationInfo page components
///import FormField from "layouts/ecommerce/products/new-product/components/FormField";
import FormField from "layouts/new-notification/components/FormField";
import CancelFormAlert from "layouts/new-notification/alerts/CancelFormAlert";
import SendSaveAlert from "layouts/new-notification/alerts/SendSaveAlert";

function NotificationInfo() {
  //const currentDate = new Date().toLocaleDateString(); //just date.
  const currentDate = new Date().toLocaleString('en-GB', {
    hour12: false,
  }).replace(',','');
  const randId = btoa(Math.random()).slice(0, 13);
  const [postData, setPostData] = useState({
    active:true,
    createdTimestamp:`${currentDate}`,
    displayTimestamp:`${currentDate}`,
    expiringSoon:true,
    expiry:"",
    header:"", 
    itemId:"",
    message:"",
    //notificationId:`${randId}`,
    notificationId:"",
    startDate:"",
    type:"OFFERS",
  });

  const handleInput = (event) => {
    setPostData({...postData, [event.target.name]: event.target.value});
  };
  const handleInputStartDate = (selectedDates, dateStr, instance) => {
    //console.log(dateStr);
    setPostData({...postData, "startDate": dateStr});
  };
  const handleInputExpiry = (selectedDates, dateStr, instance) => {
    setPostData({...postData, "expiry": dateStr});
  };
  return (
    <SoftBox>
      <SoftTypography variant="h3" fontWeight="medium">Notification Information</SoftTypography>
      <SoftBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField onChange={handleInput} name="header" id="header1" type="text" label="Header 1*" required />
          </Grid>
          <Grid item xs={12}>
            <FormField onChange={handleInput} name="message" id="header2" type="text" label="Header 2*" required />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="block">
                  <SoftTypography component="label" variant="button" fontWeight="regular">
                    Start Date*&nbsp;&nbsp;
                  </SoftTypography>
                  <SoftDatePicker onChange={handleInputStartDate} options={{dateFormat:"d/m/Y H:i:S"}} input={{ placeholder: "Select a date" }} />
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="block">
                  <SoftTypography component="label" variant="button" fontWeight="regular">
                    Expiry*&nbsp;&nbsp;
                  </SoftTypography>
                  <SoftDatePicker onChange={handleInputExpiry} options={{dateFormat:"d/m/Y H:i:S"}} input={{ placeholder: "Select a date" }} />
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
{/**
          <Grid item xs={12} sm={8}>
            <SoftBox mb={2}>
              <SoftBox display="inline-block">
                <SoftTypography component="label" variant="button" fontWeight="regular"> 
                  Expiry*
                </SoftTypography>
              </SoftBox>
              <SoftBox ml={4} display="inline-block">
                <SoftDatePicker id="expiry" input={{ placeholder: "Select a date" }} />  
              </SoftBox>
            </SoftBox>
          </Grid>
*/}
        </Grid>
      </SoftBox>
      <SoftBox display="flex" justifyContent="flex-end" mt={3}>
        <SoftBox mr={2}>
          <CancelFormAlert />  
        </SoftBox>
        <SendSaveAlert postData={postData}/>
      </SoftBox>
    </SoftBox>
  );
}

export default NotificationInfo;
