/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
import { useLocation } from "react-router-dom";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftTagInput from "components/SoftTagInput";
import SoftButton from "components/SoftButton";
import $ from "jquery";
import SoftDatePicker from "components/SoftDatePicker";
import Swal from "sweetalert2";

// Settings page components
import FormField from "layouts/settings/components/FormField";

// Data
import selectData from "layouts/settings/components/BasicInfo/data/selectData";

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function BasicInfo() {
  const [skills, setSkills] = useState(["react", "angular"]);
  let user_login = JSON.parse(localStorage.getItem('user_login'));
  let userid = user_login.adminId;
  const [data, setData] = useState([]);

  const handleInputDob = (selectedDates, dateStr, instance) => {
    setData({...data, "dob": dateStr});
  };

  const updateProfile = () => {
    $(".errCs").remove();
      let invalid = 0;

      let requestData = JSON.parse(localStorage.getItem("user_login"));
      let requiredMessage = "<span class='errCs'>Required!</span>";
      let months = {"January":"01","February":"02","March":"03","April":"04","May":"05","June":"06","July":"07","August":"08","September":"09","October":"10","November":"11","December":"12"};

      let year;
      let day;
      let gender;
      let month;

      $(".css-mxm12o-SingleValue").map(function(){
        let values = $(this).text();
        gender = values;
      });

      if (data.dob) {
        requestData["dob"] = data.dob;
      }

      if (gender) {
        requestData["gender"] = gender.toLowerCase();
      }

      if ($("#email").val()!=="") {
        requestData["email"] = $("#email").val();
      } else {
        $("#email").parent().parent().append(requiredMessage);
        invalid +=1;
      }

      if ($("#confirm_email").val()=="") {
        $("#confirm_email").parent().parent().append(requiredMessage);
        invalid +=1;
      }

      if ($("#firstName").val()!=="") {
        requestData["firstName"] = $("#firstName").val();
      } else {
        $("#firstName").parent().parent().append(requiredMessage);
        invalid +=1;
      }

      if ($("#language").val()!=="") {
        requestData["language"] = $("#language").val();
      } else {
        $("#language").parent().parent().append(requiredMessage);
        invalid +=1;
      }

      if ($("#lastName").val()!=="") {
        requestData["lastName"] = $("#lastName").val();
      } else {
        $("#lastName").parent().parent().append(requiredMessage);
        invalid +=1;
      }

      if ($("#location").val()!=="") {
        requestData["location"] = $("#location").val();
      } else {
        $("#location").parent().parent().append(requiredMessage);
        invalid +=1;
      }

      if ($("#phoneNo").val()!=="") {
        requestData["phoneNo"] = $("#phoneNo").val();
      } else {
        $("#phoneNo").parent().parent().append(requiredMessage);
        invalid +=1;
      }


      if (invalid==0) {

        axios.post(`${ApiRequests.adminUpdateDetail}`, requestData)
        .then((response) => {
          if (response) {
            Swal.fire({
              title: "Success!",
              text:"Your profile has been changed.",
              icon: "success",
              confirmButtonText: "Continue",
            }).then((result) => {
              if(result.isConfirmed) {
                window.location.reload();
              }
            })
          }
        });

      }
  }

  const readUser = async () => {
    try{
        const response = await axios
        .get("/adminRest/api/readAdminDetails/"+userid)
        .then((response) => {
        setData(response.data)
        localStorage.setItem("userData"+userid,JSON.stringify(response.data))

        $("#email").val(response.data.email);
        $("#firstName").val(response.data.firstName);
        $("#lastName").val(response.data.lastName);
        $("#gender").val(response.data.gender);
        $("#confirm_email").val(response.data.email);
        $("#location").val(response.data.location);
        $("#phoneNo").val(response.data.phoneNo);
        $("#language").val(response.data.language);
      });
    }catch(err){
      if(err.response){
        //Not in the 200 response range.
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      }
      else{
        console.log(`Error: ${err.message}`);
      }
    }
  };

  useEffect(() => {

    readUser();

  }, []);

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5">Basic Info</SoftTypography>
      </SoftBox>
      <SoftBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField id="firstName" placeholder={data?.firstName} label="first name"/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField id="lastName" placeholder={data?.lastName} label="last name"/>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Gender
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect id="gender" placeholder={data.gender?data.gender.charAt(0).toUpperCase()
  + data.gender.slice(1):""} options={selectData.gendersetting} />
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Birth Date
                    </SoftTypography>
                  </SoftBox>
                  <SoftDatePicker onChange={handleInputDob} options={{dateFormat:"d/m/Y H:i:S"}} value={data?.dob}/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField id="email"
              label="email"
              placeholder="example@email.com"
              inputProps={{ type: "email" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              id="confirm_email"
              label="confirmation email"
              placeholder={data?.email}
              inputProps={{ type: "email" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField id="location" label="your location" placeholder="Sydney, A" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField id="phoneNo"
              label="phone number"
              placeholder="+40 735 631 620"
              inputProps={{ type: "number" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField id="language" label="language" placeholder="English" />
          </Grid>
        </Grid>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
        >
          <SoftBox ml="auto">
            <SoftButton onClick={updateProfile} variant="gradient" color="dark" size="small">
              update profile
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default BasicInfo;
