// import hooks.
import { useEffect, useState } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftDatePicker from "components/SoftDatePicker";

// EditNotification page components
import FormField from "layouts/edit-notification/components/FormField";

function NotificationInfo({ postData, setPostData }) {
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  let notificationId = params.get('notificationId');

  /// axios req & res
  const readNotification = async () => {
    try{
        const response = await axios
        .get(`${ApiRequests.readNotification+notificationId}`)
        .then((response) => {
        setPostData(response.data)
       });
    }catch(err){
      if(err.response){
        //Not in the 200 response range.
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      }
      else{
        console.log(`Error: ${err.message}`);
      }
    }
  };
/// tell React that your component needs to do something after render.
  useEffect(() => {  
    readNotification();
  }, []);
/**
 * update API function.
 */
  const handleInput = (event) => {
    setPostData({...postData, [event.target.name]: event.target.value});
  };
  const handleInputStartDate = (selectedDates, dateStr, instance) => {
    //console.log(dateStr);
    setPostData({...postData, "startDate": dateStr});
  };
  const handleInputExpiry = (selectedDates, dateStr, instance) => {
    setPostData({...postData, "expiry": dateStr});
  };
  return (
    <Card sx={{ overflow: "visible" }}>
      <SoftBox py={6} px={3}>
        <SoftTypography variant="h5" fontWeight="regular">Notification Information</SoftTypography>
        <SoftBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField type="text" id="header1" label="Header 1*" value={postData?.header} onChange={handleInput} name="header" required/>
            </Grid>
            <Grid item xs={12}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography component="label" variant="button" fontWeight="regular">
                  Header 2*&nbsp;&nbsp;
                </SoftTypography>
              </SoftBox>
              <SoftInput id="header2" value={postData?.message} multiline rows={5} onChange={handleInput} name="message" />
              {/**<FormField type="text" label="Header 2*" placeholder={data?.message} required/>*/}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="block">
                    <SoftTypography mb={2} component="label" variant="button" fontWeight="regular">
                      Start Date*&nbsp;&nbsp;
                    </SoftTypography>
                    <SoftDatePicker value={postData?.startDate} onChange={handleInputStartDate} options={{dateFormat:"d/m/Y H:i:S"}} input={{ placeholder: "Select a date" }} />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="block">
                    <SoftTypography mb={2} component="label" variant="button" fontWeight="regular">
                      Expiry*&nbsp;&nbsp;
                    </SoftTypography>
                    <SoftDatePicker value={postData?.expiry} onChange={handleInputExpiry} options={{dateFormat:"d/m/Y H:i:S"}} input={{ placeholder: "Select a date" }} />
                  </SoftBox>
                </Grid>
              </Grid>
            </Grid>
{/**             
            <Grid item xs={12} sm={8}>
              <SoftBox mb={2}>
                <SoftBox display="inline-block">
                  <SoftTypography component="label" variant="button" fontWeight="regular">
                    Expiry*
                  </SoftTypography>
                </SoftBox>
                <SoftBox ml={4} display="inline-block">
                  <SoftDatePicker input={{ placeholder: `${data?.expiry}` }} />
                </SoftBox>
              </SoftBox>
            </Grid>
*/}
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}
NotificationInfo.propTypes = {
  postData: PropTypes.object,
  setPostData: PropTypes.func,
};

export default NotificationInfo;
