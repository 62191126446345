// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
// @mui material components
//import { DropzoneArea } from 'material-ui-dropzone';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftDropzone from "components/SoftDropzone";

function Media({postData, setPostData}) {
/** 
  const handleFeaturedImg = (files) => {
    ///
    files.map(file =>
      setPostData({...postData, "featuredImageURL": file.path})
    );
    ///
    const testImg = "stock-photo-white-sport-shoes-runners";
    setPostData({...postData, "featuredImageURL": testImg});
  };
  const handleOfferImg = (files) => {
    //const imgArr = files.map(file => (file.path));
    //console.log(imgArr);
    //setPostData({...postData, "offerImageURLs": imgArr});
    const testImg = ["stock-photo-white-sport-shoes-runners"];
    setPostData({...postData, "offerImageURLs": testImg});
  };
*/
  const handleFeaturedImg = (event) =>{
    const imgPath = event.target.value;
    const img = imgPath.replace(/^.*[\\\/]/, '');

    let data = new FormData();
    data.append("file",event.target.files[0]);

    axios.post(`${ApiRequests.uploadFeatureImage}`, data).then((response) => {
      setPostData({...postData, "featuredImageURL": response.data[0]});
    }).catch((err) => {
      console.log(err);
    }); 
  };
  const handleOfferImg = (event) =>{
    let arr_img = [];

    let files = event.target.files

    let data = new FormData();
    for (var i=0;i<files.length;i++) {
      data.append("file",files[i]);
    }

    axios.post(`${ApiRequests.uploadOfferImage}`, data).then((response) => {
      setPostData({...postData, "offerImageURLs": response.data});
    }).catch((err) => {
      console.log(err);
    });
  };
  return (
    <SoftBox>
      <SoftTypography variant="h3" fontWeight="medium">Media</SoftTypography>
      <SoftBox mt={3}>
        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SoftTypography component="label" variant="button" fontWeight="regular" className="lbl-txt">
            Featured Image
          </SoftTypography>
        </SoftBox>
        <SoftBox component="div">
          <SoftBox component="label" className="lbl-file-type">
            Insert image here to upload
            <SoftBox 
              component="input" 
              name="featuredImageURL" 
              type="file"
              accept="image/*"
              className="input-file-type" 
              onChange={handleFeaturedImg}
            />
          </SoftBox>
        </SoftBox>
{/*
        <SoftDropzone onChange={handleFeaturedImg} options={{ addRemoveLinks: true }} />
        <DropzoneArea onChange={handleFeaturedImg.bind(this)} dropzoneClass="elem-dropzone featuredImg" showAlerts={false} />
*/}
      </SoftBox>
      <SoftBox mt={3}>
        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SoftTypography component="label" variant="button" fontWeight="regular" className="lbl-txt">
            Offer Images
          </SoftTypography>
        </SoftBox>
        <SoftBox component="div">
          <SoftBox component="label" className="lbl-file-type">
            Insert image here to upload
            <SoftBox 
              component="input" 
              name="offerImageURLs"
              type="file"
              accept="image/*"
              className="input-file-type"
              multiple 
              onChange={handleOfferImg}
            />
          </SoftBox>
        </SoftBox>
{/*
        <SoftDropzone onChange={handleOfferImg} options={{ addRemoveLinks: true }} />
        <DropzoneArea onChange={handleOfferImg.bind(this)} dropzoneClass="elem-dropzone offerImg" showAlerts={false} />
*/}
      </SoftBox>
    </SoftBox>
  );
}

Media.propTypes = {
  postData: PropTypes.object,
  setPostData: PropTypes.func,
};

export default Media;
