import AnalyticsTable from "examples/Tables/AnalyticsTable";
import PropTypes from "prop-types";
// Icon
import ShopSvg from "assets/images/shuz_shop-svg.svg";
import { array } from "prop-types";

function BranchSignupTable({data, setData}){

  const branchSignupsData = data?.branchSignups;
  
  let branchSignupsTableData = [];

  if (typeof branchSignupsData !== 'undefined' && branchSignupsData !== null) {
      //console.log(Object.keys(branchSignupsData));

    

    Object.keys(branchSignupsData).forEach(key => {
      
      let obj = {
        Branch: [ShopSvg, key],
        "Signups this month": branchSignupsData[key][0],
        signups: branchSignupsData[key][1],
      }

      branchSignupsTableData.push(obj);
    });
  /*
  const branchSignups = Object.keys(branchSignupsData).map((obj) => (
    obj
  ));
  console.log(branchSignups);
*/
  } else {
    // 👇️ this runs
    console.log('⛔️ Object is falsy');
  }

  return(
    <>
      <AnalyticsTable title="Branch Signups" rows={branchSignupsTableData} />
      {/*
      <AnalyticsTable title="Branch Signups" rows={branchSignupsTblData} />
      
      */}
    </>
  );
}

BranchSignupTable.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
};

export default BranchSignupTable;
