// react-router-dom components
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import SoftButton from "components/SoftButton";

// Custom Components.
import StatCard from "layouts/dashboard/components/StatCard";
import DashboardDoughnutChartData from "layouts/dashboard/components/DashboardDoughnutChartData";
import CalendarEventComponent from "layouts/dashboard/components/CalendarEventComponent";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
import breakpoints from "assets/theme/base/breakpoints";
import GroupsIcon from "@mui/icons-material/Groups";

function Default() {
  const { values } = breakpoints;
  const { size } = typography;

  const [data, setData] = useState([]);

  const getAnalyticsData = async () => {
      try{
          const response = await axios
          .get(`${ApiRequests.getAnalyticsData}`)
          .then((response) => {
          setData(response.data)
          //console.log(response)
      });
      }catch(err){
          if(err.response){
          //Not in the 200 response range.
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
          }
          else{
          console.log(`Error: ${err.message}`);
          }
      }
  }

  useEffect(() => {  
      getAnalyticsData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3} p={1}>
          <Link to="/offers">
            <SoftButton variant="gradient" color="warning" size="large">
              create new offer
            </SoftButton>
          </Link>
        </SoftBox>
        <SoftBox mb={3}>
          <Grid container spacing={3}>{/*PARENT GRID*/}
            <Grid item xs={12} lg={6}>{/*CHILD*/}
              <Grid container spacing={3}>
                <StatCard data={data} />
                <Grid item xs={12} sx={{ height: "100%" }}>
                    <SoftBox position="relative" sx={{ height: "100%" }}>
                      <DashboardDoughnutChartData data={data}/>
                    </SoftBox>
                </Grid>
              </Grid>
            </Grid>{/*END CHILD*/}
            <Grid item xs={12} lg={6}>{/*CHILD*/}
              <Grid container spacing={3}>
                <Grid item xs={12} sx={{ height: "max-content" }}>
                  <CalendarEventComponent />
                </Grid>
              </Grid>
            </Grid>{/*END CHILD*/}
          </Grid>{/*END PARENT GRID*/}
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
