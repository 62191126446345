import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
const defaultImage = "";

function BrandImage({ postData, setPostData }) {

  const handleInput = (event) => {
    setPostData({...postData, [event.target.name]: event.target.value});
  };

  const handleRemoveBrandImg = (event) => {
    setPostData({...postData, "brandImageUrl": defaultImage})
  };

  const handleDropzone = (event) => {
    const imgPath = event.target.value;
    const img = imgPath.replace(/^.*[\\\/]/, '');

    let data = new FormData();
    data.append("file",event.target.files[0]);

    axios.post(`${ApiRequests.uploadBrandImage}`, data).then((response) => {
      setPostData({...postData, "brandImageUrl": response.data});
    }).catch((err) => {
      console.log(err);
    }); 
    
  }
  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5" fontWeight="regular">
          Brand Image
        </SoftTypography>
        {/***/}
        <SoftBox
          component="img"
          src={postData?.brandImageUrl}
          alt="Brand Image"
          borderRadius="lg"
          shadow="lg"
          width="90%"
          my={3}
        />
        
        <SoftBox display="flex">
          <SoftBox mr={1}>
          <SoftBox 
            variant="gradient" 
            color="dark" 
            size="small" 
            label="edit" 
            component="input" 
            type="file" 
            name="brandImageUrl" 
            accept="image/*" 
            onChange={handleDropzone} />
{/*
                <SoftButton variant="gradient" color="dark" size="small">
                  edit                  
                </SoftButton>
*/}
          </SoftBox>
          <SoftButton onClick={handleRemoveBrandImg} variant="outlined" color="dark" size="small">
            remove
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

BrandImage.propTypes = {
  postData: PropTypes.object,
  setPostData: PropTypes.func,
};

export default BrandImage;
