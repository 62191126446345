// import hooks.
import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// BrandInfo page components
import FormField from "layouts/new-branch/components/FormField";
import CancelFormAlert from "layouts/new-branch/alerts/CancelFormAlert";
import CreateSaveAlert from "layouts/new-branch/alerts/CreateSaveAlert";

function BrandInfo() {
  const currentDate = new Date().toLocaleString('en-GB', {
    hour12: false,
  }).replace(',','');
  const randId = btoa(Math.random()).slice(0, 13);
  const [postData, setPostData] = useState({
    active:true,
    branchCode: "",
    //branchId:`${randId}`,
    branchId:"",
    //createdDate:`${currentDate}`,
    branchName: "",
  });
  const handleInput = (event) => {
    setPostData({...postData, [event.target.name]: event.target.value});
  };
 
  return (
    <SoftBox>
      <SoftTypography variant="h3" fontWeight="medium">Brand Information</SoftTypography>
        <SoftBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField type="text" label="Branch Name*" placeholder="Branch Name*" onChange={handleInput} name="branchName" required />
            </Grid>
            <Grid item xs={12}>
              <FormField type="text" label="Branch Code*" placeholder="Branch Code*" onChange={handleInput} name="branchCode" required />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox display="flex" justifyContent="flex-end" mt={3}>
          <SoftBox mr={2}>
            <CancelFormAlert />
          </SoftBox>
          <CreateSaveAlert postData={postData}/>
        </SoftBox>
    </SoftBox>
  );
}

export default BrandInfo;
