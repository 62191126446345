// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// NewOffer page components
///import FormField from "layouts/ecommerce/products/new-product/components/FormField";
import FormField from "layouts/new-offers/components/FormField";

function Notification({postData, setPostData}) {
  const notificationCategoryOption = [
    { value: "OFFERS", label: "OFFERS" },
    { value: "MOBILE", label: "MOBILE"},
    { value: "GENERAL", label: "GENERAL" },
    { value: "SALE", label: "SALE" },
    { value: "EXPIRED", label: "EXPIRED" },
  ];
  
  const handleInput = (event) => {
    setPostData({...postData, [event.target.name]: event.target.value});
  };
  const handleSelect = (option) => {
    //console.log(option) //array as the select is multi select.
    let options = [];
    option.map(val =>
      options.push(val.value)
    );
    setPostData({...postData, "notificationCategory": options})
  };

  return (
    <SoftBox>
      <SoftTypography variant="h3" fontWeight="medium">Notification</SoftTypography>
      <SoftBox mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormField value={postData?.notificationText} onChange={handleInput} name="notificationText" type="text" label="text for notification" placeholder="" />
          </Grid>
          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="button" fontWeight="regular">
                Notification category
              </SoftTypography>
            </SoftBox>
            <SoftSelect
              onChange={handleSelect}
              //defaultValue={[{ value: "MOBILE", label: "MOBILE"},]}
              options={notificationCategoryOption}
              size="large"
              isMulti
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

Notification.propTypes = {
  postData: PropTypes.object,
  setPostData: PropTypes.func,
};

export default Notification;
