import AnalyticsReportsDoughnutChart from "examples/Charts/DoughnutCharts/AnalyticsReportsDoughnutChart";
import PropTypes from "prop-types";

function AnalyticsDoughnutChartData({data, setData}){
    const values = [data?.female, data?.male, data?.others];
    
    let sum = 0;
    values.map(x => sum += x);
    //console.log(sum);
    const doughnutChartData = {
            labels: ["Female", "Male", "Others",],
            datasets: {
            label: "Users",
            backgroundColors: ["primary", "info", "secondary", "success", "warning"],
            data: values,
        },
    };

    return(
        <>
            <AnalyticsReportsDoughnutChart
                title="Gender Demographic"
                count={{ number: `${sum}`, text: "Users" }}
                chart={doughnutChartData}
                tooltip="Gender Demographic in terms of users"
            />
        </>
    );
}

AnalyticsDoughnutChartData.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
};

export default AnalyticsDoughnutChartData;