/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Web app by: https://www.speire.ie
* Copyright 2023

 =========================================================
*
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import PageCustomLayout from "examples/LayoutContainers/PageCustomLayout";

// Layout components
import CardImageLayout from "layouts/about-shuzgroup/components/CardImageLayout";
import Footer from "layouts/about-shuzgroup/components/Footer";

// Images
import ShuzGroupNavLogo from "assets/images/shuzgroup-logo-white-svg.svg"
import ShuzGroupLogo from "assets/images/shuzgroup-logo-white-lrg-svg.svg";
import SkechersLogo from "assets/images/skechers-logo-white-svg.svg";
import LifeSoleLogo from "assets/images/life-sole-logo-svg.svg";
import TeamChatImg from "assets/images/Shuz4U-our-values.jpeg";

function AboutSkechers() {
  return (
    <PageCustomLayout>
      <SoftBox bgColor="#000" mb={0} id="about-skechers-pg">
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" sx={{p:'0 6% 2%'}} className="nav-section">
          <Link to="/about-us">  
            <SoftBox lineHeight={1}>
              <SoftBox
                component="img"
                src={ShuzGroupNavLogo}
                title="ShuzGroup Logo"
                alt="ShuzGroup Logo"
                my={3}
              />
            </SoftBox>
          </Link>
          <Stack spacing={1} direction="row">
            <SoftButton href="#contact-section" variant="contained" color="white" size="medium">
              Contact us
            </SoftButton>
          </Stack>
        </SoftBox>
        <SoftBox className="content-section">
          <Grid container justifyContent="center" sx={{pb:'4%'}} className="hero-section">
            <Grid item xs={12} lg={8}>
              <SoftBox mt={2} mb={1} textAlign="center">
                <SoftBox>
                  <SoftBox
                    component="img"
                    src={ShuzGroupLogo}
                    title="ShuzGroup Logo"
                    alt="ShuzGroup Logo"
                    my={3}
                  />
                  <SoftTypography mb={2} variant="h1" fontWeight="regular" color="white">
                    Shuzgroup Ireland
                  </SoftTypography>
                  <SoftTypography variant="body 2" fontWeight="regular" color="white">
                    Shuz4U Distribution ltd are an Irish owned business based in Little Island, Cork. We opened the first Skechers Ireland franchise in Henry St., Dublin in 2010. We have gone from strength to strength ever since and now have 23 Skechers Ireland stores nationwide. This includes, 16 concept stores in the Republic of Ireland, 4 concept stores in Northern Ireland and 3 outlet stores. In 2023, we launched a multi-brand footwear and apparel website called Life and Sole.
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" sx={{pb:'4%'}}>
            <Grid item xs={12} lg={8}>
              <SoftBox mt={2} mb={1} textAlign="center">
                <SoftTypography mb={2} variant="h3" fontWeight="regular" color="white">
                  Our brands
                </SoftTypography>
              </SoftBox>
            </Grid>
          </Grid>
          <Grid container 
            justifyContent="center" 
            spacing={{ xs:4, md:2, lg:1, xl:0 }} 
            alignItems="center" 
            sx={{pb:'6%'}} 
            className="skechers-section">
            <Grid item xs={12} lg={4}>
              <SoftBox textAlign="center">
                <SoftBox
                  component="img"
                  src={SkechersLogo}
                  title="Skechers Logo"
                  alt="Skechers Logo"
                />
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={4}>
              <SoftBox>
                <SoftTypography mb={2} variant="h2" fontWeight="regular" color="white">
                    Skechers
                </SoftTypography>
                <Grid container spacing={1} direction="column" p={0}>
                  <Grid item xs={12}>
                    <SoftTypography variant="body 2" fontWeight="bold" color="white">
                      Skechers started in 1992 and has grown in to one of the most popular footwear brands in the world. For more than 25 years, Skechers has built their business on the foundation of amazing product.
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <SoftTypography variant="body 2" fontWeight="regular" color="white">
                      Our extensive collections of lifestyle and performance footwear span more than 3000 styles for men, women, and children. Trend-forward, comfortable, and innovative, Skechers&apos; footwear options meet most any need or occasion. Plus, with a range of apparel and accessories complementing the footwear collections, consumers can dress head to toe in Skechers, making it a complete lifestyle brand. Skechers offers a wide range of signature features among which, its patented Arch Fit Technology, Skechers Hyper Burst Technology, Skechers Air-Cooled Memory Foam and Skechers Hands Free Slip-ins Technology are just a select example.
                    </SoftTypography>
                  </Grid>
                </Grid>
              </SoftBox>
            </Grid>
          </Grid>
          <Grid container 
            justifyContent="center" 
            spacing={{ xs:4, md:2, lg:1, xl:0 }} 
            alignItems="center" 
            sx={{pb:'6%'}} 
            className="life-sole-section">
            <Grid item xs={12} lg={4}>
              <SoftBox>
                <SoftTypography mb={2} variant="h2" fontWeight="regular" color="white">
                    Life and Sole
                </SoftTypography>
                <Grid container spacing={1} direction="column" p={0}>
                  <Grid item xs={12}>
                    <SoftTypography variant="body 2" fontWeight="bold" color="white">
                      Life and Sole is a multi-brand footwear and apparel company. Comfort is at the heart of our brand, and we want to provide on trend styles that look good and feel even better.
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <SoftTypography variant="body 2" fontWeight="regular" color="white">
                      Whether you need something for long walks, lazy weekends or just an everyday staple, Life and Sole has everything you need to stay trendy and comfortable whatever your lifestyle. At Life and Sole, we are committed to reducing the impact we have on the planet and we&apos;re always looking for new ways to get better. Many of our brands are crafted using recycled materials and are completely biodegradable. We want to reduce our contribution to the climate crisis through incorporating an earth-friendly philosophy and by ensuring that our packaging and shipment processes are as green as possible.
                    </SoftTypography>
                  </Grid>
                </Grid>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={4}>
              <SoftBox textAlign="center">
                <SoftBox
                  component="img"
                  src={LifeSoleLogo}
                  title="Life and Sole Logo"
                  alt="Life and Sole Logo"
                />
              </SoftBox>
            </Grid>
          </Grid>
          <Grid container 
            justifyContent="flex-start" 
            spacing={0} alignItems="center" 
            /*sx={{p:'0 0 0 8%', transform:'translateY(40%)'}}*/
            sx={{p:'0 0 0 8%'}}
            className="card-txt-section">
            <Grid item xs={12} md={6} lg={4}>
              <CardImageLayout />
            </Grid>
          </Grid>
          <Grid container 
            justifyContent="center" 
            spacing={0} alignItems="center" 
            sx={{pb:'6%', mt:'-8em'}} 
            className="img-block-section">
            <Grid item xs={12}>
              <SoftBox textAlign="center">
                <SoftBox
                  component="img"
                  src={TeamChatImg}
                />
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>{/* CONTENT-SECTION */}
      </SoftBox>
      <Footer />
    </PageCustomLayout>
  );
}

export default AboutSkechers;
