/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Web app by: https://www.speire.ie
* Copyright 2023

 =========================================================
*
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import IllustrationLayout from "layouts/about-us/components/IllustrationLayout";

// Images
import rocket from "assets/images/illustrations/rocket-white.png";

function Illustration() {
  const [agreement, setAgreemnet] = useState(true);

  const handleSetAgremment = () => setAgreemnet(!agreement);

  return (
    <IllustrationLayout
    /*  title="Skechers"
      description="The comfort technology company"
      
      illustration={{
        image: rocket,
      }}
      */
    >
      <SoftBox lineHeight={1}>
        <SoftTypography variant="h1">
          Shuzgroup
        </SoftTypography>
        <SoftTypography variant="h5">
        Shuz4U Ltd is the Irish franchise of the Skechers brand with 23 stores nationwide. SKECHERS are an award-winning global leader in the lifestyle footwear industry, designing, developing and marketing lifestyle footwear that appeals to trend-savvy men, women and children.
        </SoftTypography>
      </SoftBox>
    </IllustrationLayout>
  );
}

export default Illustration;
