// import hooks.
import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import GroupsIcon from "@mui/icons-material/Groups";
import PropTypes from "prop-types";

function StatCard({ data }){
/*
    const values = [data?.female, data?.male, data?.others];
    let sum = 0;
    values.map(x => sum += x);
*/
    return(
        <>
            <Grid item xs={12} lg={6}>
                <SoftBox mb={3}>
                    <MiniStatisticsCard
                        title={{ text: "Users", fontWeight: "bold" }}
                        count={data?.totalUserCount}
                        //percentage={{ color: "warning", text: "+55%" }}
                        icon={{ color: "dark", component: <GroupsIcon /> }}
                    />
                </SoftBox>
            </Grid>
            <Grid item xs={12} lg={6}>
                <SoftBox mb={3}>
                    <MiniStatisticsCard
                        title={{ text: "new users", fontWeight: "bold" }}
                        count={data?.newUserCount}
                        //percentage={{ color: "warning", text: "+3%" }}
                        icon={{ color: "dark", component: <GroupsIcon /> }}
                    />
                </SoftBox>
            </Grid>
        </>
    );
}

StatCard.propTypes = {
  //data: PropTypes.object,
  //data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  data: PropTypes.shape({
    newUserCount: PropTypes.number,
    totalUserCount: PropTypes.number,
  }),
  //setData: PropTypes.func,
};

export default StatCard;