import { useState } from "react";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewStore page components
import ContinueSaveAlert from "layouts/new-store/alerts/ContinueSaveAlert";
import StoreInfo from "layouts/new-store/components/StoreInfo";

function NewStore() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox pt={6} pb={8}>
        
        <SoftBox sx={{display:"grid", gridTemplateColumns: "1fr repeat(2, auto)", justifyItem:"center"}}>
          <SoftBox sx={{ gridColumnStart:"1" }}> 
            <Grid container justifyContent="center">
              <Grid item xs={12} lg={8}>
                <SoftBox mb={4} textAlign="center">
                  <SoftBox mb={1}>
                    <SoftTypography variant="h1" fontWeight="regular">
                      Create New Store
                    </SoftTypography>
                  </SoftBox>
                  <SoftTypography variant="h6" fontWeight="regular" color="secondary">
                    More information about new store.
                  </SoftTypography>
                </SoftBox>
                <Card sx={{ overflow: "visible" }}>
                  <SoftBox p={2} lineHeight={1}>
                    <StoreInfo />
                  </SoftBox>
                </Card>            
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox sx={{ml:""}} pr={8}>
            <ContinueSaveAlert />
          </SoftBox>
        </SoftBox>
{/*
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <Grid container justifyContent="center">
              <Grid item xs={12} lg={8}>
                <SoftBox mb={4} textAlign="center">
                  <SoftBox mb={1}>
                    <SoftTypography variant="h1" fontWeight="regular">
                      Create New Store
                    </SoftTypography>
                  </SoftBox>
                  <SoftTypography variant="h6" fontWeight="regular" color="secondary">
                    More information about new store.
                  </SoftTypography>
                </SoftBox>
                <Card sx={{ overflow: "visible" }}>
                  <SoftBox p={2} lineHeight={1}>
                    <StoreInfo />
                  </SoftBox>
                </Card>            
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Link to="/stores">
              <SoftButton variant="outlined" color="secondary" size="small">
                exit
              </SoftButton>
            </Link>
          </Grid>
        </Grid>
*/}
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewStore;
