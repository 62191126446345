import { useMemo } from "react";

// react-router-dom components
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import SoftButton from "components/SoftButton";
import AnalyticsTable from "examples/Tables/AnalyticsTable";
import StatCard from "layouts/analytics/components/StatCard";
import AnalyticsDoughnutChartData from "layouts/analytics/components/AnalyticsDoughnutChartData";
import BranchSignupTable from "layouts/analytics/components/BranchSignupTable";

// Soft UI Dashboard PRO React base styles
import GroupsIcon from "@mui/icons-material/Groups";

// Data
import branchSignupsData from "layouts/analytics/data/branchSignupsData";

function Analytics() {

  const [data, setData] = useState([]);
/// axios req & res
    const getAnalyticsData = async () => {
        try{
            const response = await axios
            .get(`${ApiRequests.getAnalyticsData}`)
            .then((response) => {
            setData(response.data)
            //console.log(response)
            });
        }catch(err){
            if(err.response){
            //Not in the 200 response range.
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
            }
            else{
            console.log(`Error: ${err.message}`);
            }
        }
    }

    useEffect(() => {  
        getAnalyticsData();
    }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>{/*PARENT GRID*/}
            <Grid item xs={12} lg={6}>{/*CHILD*/}
              <Grid container spacing={3}>
                <StatCard data={data} setData={setData}/>
                <Grid item xs={12}>
                  <SoftBox mb={3} position="relative">
                    {/*<AnalyticsTable title="Branch Signups" rows={branchSignupsData} />*/}
                    <BranchSignupTable data={data} setData={setData}/>
                  </SoftBox>
                </Grid>
              </Grid>
            </Grid>{/*END CHILD*/}
            <Grid item xs={12} lg={6} sx={{ height: "100%" }}>{/*CHILD*/}
              <Grid container spacing={3} sx={{ height: "100%" }}>
                <Grid item xs={12}>
                  <AnalyticsDoughnutChartData data={data} setData={setData}/>
                </Grid>
              </Grid>
            </Grid>{/*END CHILD*/}
          </Grid>{/*END PARENT GRID*/}
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics;
