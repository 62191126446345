// import hooks.
import { useEffect, useState } from "react";
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
import { useLocation } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// EditUser page components
import FormField from "layouts/edit-user/components/FormField";
import PropTypes from "prop-types";

function CardInfo({data, setData}) {

  const handleInput = (event) => {
    setData({...data, [event.target.name]: event.target.value});
  };

  return (
    <Card>
      <SoftBox p={3}>
        <SoftTypography variant="h5" fontWeight="regular">
          Card Information
        </SoftTypography>
        <SoftBox mt={1} className="cardInfo">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormField type="number" onChange={handleInput} name="cardNo" label="card number" value={data?.cardNo}/>
            </Grid>
            <Grid item xs={12}>
              <FormField type="number" onChange={handleInput} name="cardPin" label="card pin" value={data?.cardPin}/>
            </Grid>
            <Grid item xs={12}>
              <FormField type="text" onChange={handleInput} name="localStore" label="local store" value={data?.localStore}/>
            </Grid>
            <Grid item xs={12}>
              <FormField type="number" onChange={handleInput} name="pointsAccumulated" label="points accumulated" min="0" value={data?.pointsAccumulated}/>
            </Grid>
            <Grid item xs={12}>
              <FormField type="number" onChange={handleInput} name="pointsAccumulated" label="points worth" min="0" value={data?.pointsAccumulated}/>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

CardInfo.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
};

export default CardInfo;
