//import hooks.
import axios from "AxiosModule";
import ApiRequests from "ApiRequests";
import $ from "jquery";

// sweetalert2 components
import Swal from "sweetalert2";

// react-router-dom components
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

// Sweet Alerts page components
import Template from "layouts/edit-user/alerts/SaveChanges/Template";

function isNumeric(str) {
  if (typeof str != "string") return false
  return !isNaN(str) &&
         !isNaN(parseFloat(str))
}

function SaveChanges({data, setData}) {
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  let userid = params.get('uid');
  const navigate = useNavigate();
  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button btn-save button-success",
        cancelButton: "button btn-continue",
      },
      buttonsStyling: false,
    });

    let notValid = 0;

    let optionalField = ["title","gender","phoneNo"];

    $(".errCs").remove();
    $("input").each(function(i,obj){
      let attrid = $(obj).attr("id");
      let selection = false;  

      if (typeof attrid!=="undefined") {
        if (attrid.includes("react-select")) {
          selection = true;
        }
      }

      if (!selection) {
        if ($(obj).val()==""){
          if (!optionalField.includes($(obj).attr("name"))){
            if ($(obj).attr("placeholder")=="Select a date") {
              $(obj).parent().parent().parent().append("<span class='errCs'>Required!</span>");
            } else {
              $(obj).parent().parent().append("<span class='errCs'>Required!</span>");
            }
            notValid+=1;
          }
        }
      }

      if ($(obj).attr("name")=="phoneNo" || $(obj).attr("name")=="cardPin" || $(obj).attr("name")=="cardNo") {
        if (!isNumeric($(obj).val())) {
          $(obj).parent().parent().append("<span class='errCs'> Please input numbers only!</span>");
          notValid+=1;
        }
      }

    })
    $("textarea").each(function(i,obj){
      if ($(obj).val()==""){
        if (!optionalField.includes($(obj).attr("name"))) {
          $(obj).parent().parent().append("<span class='errCs'>Required!</span>");
          notValid+=1;
        }
      }
    })
    if (notValid==0) {
      newSwal
      .fire({
        title: "Save Changes?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Save",
        reverseButtons: true,
        cancelButtonText:"Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {

          axios({
            method: "post",
            url: ApiRequests.updateUser,
            data: data,
          }).then(function (response) {
            Swal.fire({title:"Saved!", text:"Your changes have been saved.", type:"success", icon:"success"}).then(function(){
              navigate("/users");
              //window.location.href = "/users";
            });
          }).catch(function (error) {
            Swal.fire({title:"Failed!", text:error.response.data.message, type:"error", icon:"error"}).then(function(){  
            });
          });

        }
      });
    }
  };

  return (
    <Template
      action={showAlert}
    />
  );
}

SaveChanges.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
};

export default SaveChanges;
