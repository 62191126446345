// @mui material components
//import { DropzoneArea } from 'material-ui-dropzone';

function DropzoneAreaComponent(){
    return(
        <>
{/*         <DropzoneArea />*/}
        </>
    );
}
export default DropzoneAreaComponent; 