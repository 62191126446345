// import hooks.
import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import BtnSignIn from "layouts/authentication/sign-in/alerts"

// Image
import chat from "assets/images/illustrations/chat.png";

function SignIn() {
  const [rememberMe, setRememberMe] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [postEmail, setEmail] = useState("");
  const [postPwd, setPwd] = useState("");

  localStorage.removeItem("user_login");
  
  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your email and password to sign in"
      /*
      illustration={{
        image: chat,
      }}
      */
    >
      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          <SoftInput onChange={(event) => {setEmail(event.target.value)}} type="email" placeholder="Email" size="large" icon={{component: "email", direction: "left", }} required />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftInput onChange={(event) => {setPwd(event.target.value)}} type="password" placeholder="Password" size="large" icon={{component: "lock", direction: "left", }} required/>
        </SoftBox>
{/**         
        <SoftBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;Remember me
          </SoftTypography>
        </SoftBox>
*/}
        <SoftBox mt={4} mb={1}>
          <BtnSignIn postEmail={postEmail} postPwd={postPwd}/>
{/**
          <Link to="/dashboard">
            <SoftButton variant="gradient" color="warning" size="large" fullWidth>
              sign in
            </SoftButton>
          </Link>
*/}
        </SoftBox>

{/*
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <SoftTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="warning"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
*/}
        <SoftBox mt={1} textAlign="center">
          <SoftTypography 
            component={Link} 
            to="/authentication/reset-password/illustration"
            variant="button" 
            color="text" 
            fontWeight="regular">
            Forgot password?
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </IllustrationLayout>
  );
}

export default SignIn;
