import SoftBox from "components/SoftBox";

import PropTypes from "prop-types";

const SoftCustom = ({postData}) => {

	let arrCs = [];

	for (let i = 0; i < postData.offerImageURLs.length; i++) {
		arrCs.push(<SoftBox
	      component="img"
	      src={postData?.offerImageURLs[i]}
	      alt="Offer Image"
	      borderRadius="lg"
	      shadow="lg"
	      width="40%"
	      loading="lazy"
	      m={2}
	    />);
	}
	
	return arrCs
		
};

SoftCustom.propTypes = {
  postData: PropTypes.object
};

export default SoftCustom;
