// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Custom Layout components.
import StoreTable from "layouts/stores/components/StoreTable";

function StoreList() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>   
        <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
          <SoftBox lineHeight={1}>
            <SoftTypography variant="h3" fontWeight="medium">
              Our Stores
            </SoftTypography>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              A lightweight, extendable, dependency-free javascript HTML table plugin.
            </SoftTypography>
          </SoftBox>
          <Stack spacing={1} direction="row">
            <Link to="/new-store">
              <SoftButton variant="gradient" color="warning" size="large">
                + new store
              </SoftButton>
            </Link>
          </Stack>
        </SoftBox>
        <Card>
          <StoreTable />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default StoreList;
